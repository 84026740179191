<template>
  <el-dialog
    id="PromptDetailsModal"
    visible
    :before-close="close"
  >
  <div
      slot="title"
      class="d-flex align-items-center justify-content-between pr-5"
    >Copilot scenario</div>
  <div class="">
    <p><strong>Learn, how your AI assistant is interpreting your description into the variables.</strong></p>
    <p>To improve your assistant you can click on the tag and points to the right variables or remove it.</p>
  </div>
  <div class="" v-html="processPrompt"></div>
  </el-dialog>
</template>
<script>
export default {
  name: 'PromptDetailsModal',
  props: {
    prompt: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    };
  },
  computed: {
    /**
     * @entry Must use this.testToUse.definition.testgen.
     * @description Update the given prompt as a simple string to be stylized with html tag to see highlighted dimensions and input variables
     * @note Using replaceAll instead of the span. Maybe carefull in the future, is one occurence must not be catch (replaceAll, replace all occurences). 
     *       Using .span should be the solution.
     * @return Return a processed prompt with html tag. This string must be interpreted as html.
     */
    processPrompt() {
      const {assumptions, dimensions, input_variables, endorsements} = this.prompt;
      let { prompt } = this.prompt;
      Object.values({assumptions, dimensions, input_variables, endorsements}).forEach((items) => {
        prompt = this.preparePrompt({ prompt, items });
      });
      return prompt;
    },
  },
  async mounted() {

  },
  methods: {
    close() {
      this.$emit('close');
    },
    preparePrompt(options) {
      let { prompt, items } = options;
      items && Object.entries(items).forEach(([name, item]) => {
        if (item.relevant_word.length > 0) {
          prompt = prompt.replaceAll(item.relevant_word, `<div class="highlight" rel="${item.id}">${item.relevant_word}</div><div class="technical_item">${name}</div>`);
        }
      });
      return prompt;
    },
  }
};
</script>
<style lang="scss">
@import "@axatechlab/assets/scss/_variables.scss";

.highlight {
  display: inline-block;
  border-radius: 0;
  background-color: $color-highlight;
  padding: 0 5px;
  height: 24px;
  color: $color-axa-blue;
}

.technical_item {
  display: inline-block;
  border-radius: 50px;
  background-color: $color-highlight;
  border: 1px solid $color-active;
  padding: 0 10px;
  margin: 2px 2px;
  height: 24px;
  color: $color-axa-blue;
}
</style>