<template>
  <div v-if="isLoggedIn" class="p-2 flex-grow-1 flex-shrink-1 d-flex flex-column overflow-auto align-items-stretch">
    <input ref="fileUpload" type="file" hidden @input="handleSelectedFile" />
    <div class="flex-grow-1 flex-shrink-1 p-2 d-flex flex-column overflow-hidden" @dragover.prevent
      @drop.prevent.stop="handleDrop">
      <UiPaddedArea class="p-0">
        <UiToolbar>
          <span>Filters</span>
          <b-dropdown text="Sources" variant="outline-primary">
            <b-dropdown-form>
              <b-form-checkbox-group v-model="selectedSource" :options="sourcesByType"></b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
          <b-dropdown text="Types" variant="outline-primary">
            <b-dropdown-form>
              <b-form-checkbox-group v-model="insuranceType" :options="insuranceTypes"></b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
          <b-dropdown text="Entities" variant="outline-primary" class="custom-scroll">
            <b-dropdown-form>
              <b-form-checkbox-group v-model="selectedEntity" :options="entities"></b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
          <div class="flex-spacer" />
          <b-input-group class="search-group">
            <b-form-input v-model="searchQuery" class="search-input" name="search"
              :placeholder="$t(`product.search_${productType.toLowerCase()}`)" />
            <label for="search">
              <b-icon icon="search" class="search-input-icon"></b-icon>
            </label>
          </b-input-group>
          <div class="flex-spacer" />
          <template #side>
            <b-button v-if="!isGuest(tenant)" variant="primary" @click="createNewProduct">
              <i class="fas fa-plus mr-2"></i>{{ $t(`product.create.${productType.toLowerCase()}`) }}
            </b-button>
            <b-dropdown variant="outline-primary" right no-caret>
              <template #button-content>
                <b-icon-three-dots />
              </template>
              <template v-if="!isGuest(tenant)">
                <b-dropdown-item>
                  <b-button @click="$refs.fileUpload.click()">
                    {{ $t(`product.import.${productType.toLowerCase()}`) }}
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-divider />
              </template>
              <b-dropdown-item @click="exportAs('xlsx')">
                <i class="fas fa-download"></i>
                {{ $t('common.download-as-excel') }}
              </b-dropdown-item>
              <b-dropdown-item @click="exportAs('csv')">
                <i class="fas fa-download"></i>
                {{ $t('common.download-as-csv') }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </UiToolbar>
      </UiPaddedArea>
      <PaginatedProductTable :create-label="$t(`product.create.${productType.toLowerCase()}`)"
        :import-label="$t(`product.import.${productType.toLowerCase()}`)" :is-loading="isLoading"
        :product-type="productType" :products="list" :sort-by="{ prop: sortField, order: sortOrder }"
        :page-size="pagination.limit" :total="pagination.total" :page="pagination.page" @sort-change="onSort"
        @page-change="onPageChange" @delete="() => fetchProducts(true)" />
    </div>

    <!-- Product import manager -->
    <ProductImportManager :visible="showProductImportManager" :product-type="productType" :product-j-s-o-n="productJSON"
      :import-file-name="importFileName" @close="(success) => closeImportWizard(success)" />

    <!-- New product creation modal -->
    <NewProductCreationModal :product-type="productType" :visible="showProductCreationDialog"
      @close="closeProductCreationDialog" />
    <el-dialog title="Import successful" :visible.sync="showImportResult">
      <b>{{messages.filter((m) => m.type === 'SUCCESS').length}}</b>
      product(s) imported with success!
      <el-table class="import-table" :data="messages.filter(
        (m) =>
          !searchImport ||
          m.message.toLowerCase().includes(searchImport.toLowerCase())
      )
        " height="400" :row-class-name="importRowClassName" :default-sort="{ prop: 'type', order: 'ascending' }">
        <el-table-column width="150" label="Type" class-name="left_padded" sortable="true" :filters="[
          { text: 'Warnings', value: 'WARNING' },
          { text: 'Errors', value: 'ERROR' },
          { text: 'Success', value: 'SUCCESS' }
        ]" :filter-method="filterImportType">
          <template slot-scope="{ row }">
            {{ row.type }}
          </template>
        </el-table-column>
        <el-table-column label="Message" class-name="left_padded">
          <template slot="header">
            <el-input v-model="searchImport" size="mini" placeholder="Type to search" />
          </template>
          <template slot-scope="{ row }">
            {{ row.message }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- Team selector modal -->
    <el-dialog v-selector class="teamSelectorModal" :visible.sync="showTeamSelectorDialog"
      @close="closeTeamSelectorDialog()">
      <h5 class="title">Select a team</h5>

      <el-select v-model="importTeam">
        <el-option v-for="team in availableTeams" :key="team.id" :label="team.label" :value="team.id">
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn ui_button_back" @click="
          importExcelFile(importFile, importTeam) && closeTeamSelectorDialog()
          ">Import</el-button>
        <el-button class="btn ui_button_back" :disabled="isLoading" @click="closeTeamSelectorDialog()">{{
          $t('action.cancel') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Message, Loading } from 'element-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import XLSX from 'xlsx';
import { debounce } from 'lodash';
import { v4 as uuid } from 'uuid';
import NewProductCreationModal from '../components/NewProductCreationModal.vue';
import ProductImportManager from '../components/ProductImportManager.vue';
import { findAll } from '../store/products/products.actions';
// eslint-disable-next-line import/no-cycle
import * as api from '../api';
import {
  readFromFile,
  readZipImportedContent,
  exportToList,
  slugify
} from '../util';
import { productTypes } from '../const/product';
import PaginatedProductTable from '../components/Product/PaginatedProductTable';
import RoutingMixin from '../components/RoutingMixin';
import productImporters from '@/const/product.importers';

const importerNames = [productImporters.IMPORT_STANDARD, productImporters.IMPORT_TRAVELLERS, productImporters.IMPORT_LIGHT];
const sortFieldMapping = {
  author: 'author',
  product_name: 'name',
  updated_at: 'updated_at',
  latest_version: undefined,
  technical_name: 'technicalName',
  subtype: 'subtype'
};

export default {
  name: 'ProductsIndex',
  components: {
    PaginatedProductTable,
    NewProductCreationModal,
    ProductImportManager
  },
  mixins: [RoutingMixin],
  props: {
    productType: {
      type: String,
      required: true,
      validator: (value) => !!productTypes[value]
    }
  },

  data: () => ({
    searchImport: '',
    messages: [],
    imported: [],
    deleted: [],
    showImportResult: false,
    showProductCreationDialog: false,
    showProductImportManager: false,
    showTeamSelectorDialog: false,
    productTypes,
    insuranceType: [],
    selectedSource: [],
    selectedEntity: [],
    productJSON: {},
    importFileName: '',
    submittingExport: false,
    importFile: null,
    importTeam: null
  }),

  computed: {
    ...mapGetters('auth', [
      'isOwner',
      'isGuest',
      'isLoggedIn',
      'isMember',
      'isSuperAdmin'
    ]),
    ...mapGetters('maintenance', ['isMaintenance']),
    ...mapState('products', [
      'isLoading',
      'list',
      'source',
      'sources',
      'entities',
      'pagination',
      'lastExport',
      'type'
    ]),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', { user: 'getUser' }),
    ...mapState('teams', ['teams']),
    insuranceTypes() {
      return ['product', 'clause', 'policy'];
    },
    page: {
      get() {
        return parseInt(this.$route.query.page, 10) || 1;
      },
      set(page) {
        if (page === this.page) return;
        this.pushLocation({ page });
      }
    },
    count() {
      return parseInt(this.$route.query.count, 10) || 25;
    },
    sortOrder() {
      return this.$route.query.order || 'descending';
    },
    sortField() {
      return this.$route.query.field || 'updatedAt';
    },
    dropdownItems() {
      return [
        {
          command: 'delete',
          label: this.$t('action.delete'),
          class: 'ui_menu_delete',
          icon: 'far fa-trash-alt',
          variant: 'danger'
        }
      ];
    },
    searchQuery: {
      get() {
        return this.$route.query.search || '';
      },
      set(search) {
        if (search === this.searchQuery) return;
        this.pushLocation({ page: 1, search });
      }
    },
    isReadOnlyMode() {
      return (
        !this.isSuperAdmin() &&
        (!this.user.teams.some(
          (t) => t.tenant === this.tenant && t.UserTeam.team_role_id <= 4
        ) ||
          this.isMaintenance)
      );
    },
    availableTeams() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.filter(
          (t) =>
            t.tenant === this.tenant &&
            (this.isMember(t.slug) || this.isOwner(this.defaultTeam.slug))
        )
      );
    },
    defaultTeam() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.find((t) => t.slug === this.tenant)
      );
    },
    sourcesByType() {
      return [...this.sources, 'Partner'].filter((e) => e !== 'all');
    }
  },

  watch: {
    list() {
      if (!this.list || this.list.length === 0) return;
      this.list.map((p) => this.fetchProductValidation({ productId: p.id }));
    },
    pagination() {
      this.page = this.pagination.page || 1;
    },
    page() {
      this.debounceFetchProducts();
    },
    productType() {
      this.searchQuery = '';
      this.debounceFetchProducts();
      this.resetProduct();
    },
    searchQuery() {
      this.debounceFetchProducts();
    },
    sortOrder() {
      this.debounceFetchProducts();
    },
    sortField() {
      this.debounceFetchProducts();
    },
    teams() {
      this.setDefaultTeam();
    },
    selectedSource() {
      this.switchProductSource({ source: this.selectedSource });
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.fetchProducts();
      }
    },
    insuranceType() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.fetchProducts();
      }
    },
    selectedEntity() {
      if (this.page !== 1) {
        this.page = 1;
      } else {
        this.fetchProducts();
      }
    },
    tenant() {
      this.init();
    }
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      this.messages = [];
      this.selectedEntity = [];
      this.searchQuery = '';
      this.setDefaultTeam();
      this.resetProduct();
      this.fetchProductEntities();
      this.fetchProducts();
    },
    ...mapActions('sharedProperty', ['fetchProductValidation']),
    ...mapActions('product', { markAsDeleted: 'markAsDeleted', resetProduct: 'reset' }),
    ...mapActions('products', [
      'fetchAllProducts',
      'fetchProductSources',
      'fetchProductEntities',
      'switchProductSource',
      'exportProducts'
    ]),
    ...mapActions('teams', ['loadTeams']),
    pushLocation(query) {
      this.$router.push({
        path: this.$router.currentRoute.path,
        query: {
          order: this.sortOrder,
          field: this.sortField,
          page: this.page,
          search: this.searchQuery,
          ...query
        }
      });
    },
    // eslint-disable-next-line func-names
    debounceFetchProducts: debounce(function () {
      this.fetchProducts();
    }, 400),
    fetchProducts(silent = false) {
      const sortField = sortFieldMapping[this.sortField];
      this.fetchAllProducts({
        silent,
        count: 25,
        page: this.page,
        source: this.selectedSource.toString(),
        author: this.selectedEntity.toString(),
        type: this.productType,
        search: this.searchQuery,
        sort: sortField
          ? {
            field: sortField,
            direction: this.sortOrder === 'descending' ? 'DESC' : 'ASC'
          }
          : undefined,
        insuranceType: this.insuranceType
      });
    },
    closeImportWizard(importResponse) {
      this.showProductImportManager = false;
      if (importResponse) {
        this.handleSuccessImport(importResponse);
      }
      this.productJSON = {};
    },
    onSort(field, order) {
      if (order && field) {
        if (order === this.sortOrder && field === this.sortField) return;
        this.pushLocation({ order, field });
      }
    },
    onPageChange(page) {
      this.page = page;
    },
    handleSuccessImport(importResponse) {
      // if import a template file from products list we warn the user
      if (
        productTypes.TEMPLATE !== this.productType &&
        productTypes.TEMPLATE === importResponse.type
      ) {
        Message.warning(this.$t('product.import.template_uploaded_as_product'));
      } else if (
        importResponse.type &&
        productTypes.TEMPLATE === this.productType &&
        productTypes.TEMPLATE !== importResponse.type
      ) {
        Message.warning(this.$t('product.import.product_uploaded_as_template'));
      } else {
        Message.success(importResponse.message);
      }
      this.changeRouteWithCatch({
        name: 'product-home',
        params: { productId: importResponse.product_id }
      });
    },
    // eslint-disable-next-line consistent-return
    async importJSONFile(file) {
      try {
        const { data } = await api.importProduct(
          file,
          this.productType,
          this.tenant
        );
        // @TODO #119 - errors are badly handled on serverside
        if (!data.ok) {
          return Message.error(data.message);
        }
        return this.handleSuccessImport(data);
      } catch (err) {
        Message.error(err.response.data);
      }
    },
    async importerForExcel(workbook) {
      // eslint-disable-next-line no-restricted-syntax
      for (const name of importerNames) {
        // eslint-disable-next-line no-await-in-loop
        const importer = await import(`../importers/${name}`);
        if (importer && importer.canReadWorkbook(workbook) === true) {
          return importer;
        }
      }
      return null;
    },
    importRowClassName({ row }) {
      return row.type;
    },
    filterImportType(value, row) {
      return row.type === value;
    },
    async readFile(file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException('Problem parsing input file.'));
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async importOneProduct(
      product,
      replace,
      areSameProducts,
      team = this.tenant
    ) {
      if (replace) {
        const oldProducts = await this.findMatchingProducts(
          product,
          areSameProducts
        );
        // eslint-disable-next-line no-restricted-syntax
        for (const old of oldProducts) {
          // eslint-disable-next-line no-await-in-loop
          await this.markAsDeleted({ productId: old.id });
          this.deleted.push(old.name);
        }
      }
      const result = await api.uploadProduct(product, this.tenant, team);
      this.imported.push(product.name);
      return result;
    },
    async findMatchingProducts(product, areSameProducts) {
      const answer = await findAll({
        type: this.productType,
        search: product.name,
        tenant: this.tenant
      });
      const products = answer.data || [];
      if (!areSameProducts) return products;
      return products.filter((p) => {
        return areSameProducts(product, p);
      });
    },
    async startImportExcelFile(file) {
      this.importFile = file;
      let teamsWithAccess = [];
      if (this.isSuperAdmin()) {
        if (!this.teams) {
          await this.loadTeams();
        }
        teamsWithAccess = this.teams.filter((t) => t.tenant === this.tenant);
      } else {
        teamsWithAccess = this.user.teams.filter(
          (t) => t.tenant === this.tenant && t.UserTeam.team_role <= 4
        );
      }
      if (teamsWithAccess.length === 1) {
        this.importTeam = teamsWithAccess[0].id;
        return this.importExcelFile(file, this.importTeam);
      }
      // display modal
      this.showTeamSelectorDialog = true;
      return undefined;
    },
    async importExcelFile(file, team) {
      const loadingInstance = Loading.service({ fullscreen: true });
      try {
        this.messages = [];
        this.imported = [];

        const data = await this.readFile(file);
        const workbook = XLSX.read(data, { type: 'array', raw: true });
        const importer = await this.importerForExcel(workbook);
        if (!importer) {
          Message.error(this.$t('product.import.no_matching_importer_found'));
          return;
        }
        const config = importer.configuration
          ? importer.configuration() || {}
          : {};
        const replace = config.replaceExisting;
        const { areSameProducts } = importer;
        const generator = importer.productsFromWorkbook(workbook, file.name);

        // eslint-disable-next-line no-restricted-syntax
        for (const product of generator) {
          if (importer.progress) {
            loadingInstance.text = `Importing ${product.name} (${importer.progress.current} / ${importer.progress.total})`;
          }
          product.rules = product.rules.map((r) => this.regenerateGraphIds(r));
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => setTimeout(resolve, 1000));
          // eslint-disable-next-line no-await-in-loop
          const result = await this.importOneProduct(
            product,
            replace,
            areSameProducts,
            team
          );
          if (!result || result.status !== 201) {
            this.message.push({
              type: 'ERROR',
              message:
                // eslint-disable-next-line prefer-template
                '"' +
                product.name +
                '" import failed ' +
                (result && result.message
                  ? `with message : ${result.message}`
                  : ' without message')
            });
          }
        }
        loadingInstance.close();
        if (config.showResult) {
          if (importer.getMessages()) {
            this.messages = this.messages.concat(importer.getMessages());
          }
          this.imported.forEach((product) => {
            this.messages.push({
              type: 'SUCCESS',
              message:
                // eslint-disable-next-line prefer-template
                '"' +
                product +
                '" ' +
                (this.deleted.indexOf(product) >= 0 ? 'updated' : 'imported') +
                ' with success!'
            });
          });
          this.searchImport = '';
          this.showImportResult = true;
        }
        this.fetchProducts();
        this.fetchProductSources();
        this.fetchProductEntities();
        Message.success(this.$t('product.import.success'));
      } catch (err) {
        loadingInstance.close();
        Message.error(err);
      }
    },
    async importProduct(file) {
      if (file.name.endsWith('.xlsx')) {
        await this.startImportExcelFile(file);
      } else {
        // Handle a regular json file
        await this.importJSONFile(file);
      }
    },

    async handleSelectedFile(ev, dropped = false) {
      let json = null;
      const event = ev;
      const [file] = dropped ? event.dataTransfer.files : event.target.files;

      if (!file) {
        return;
      }

      if (!file.name.match(/(.json|.xlsx|.zip)$/)) {
        Message.error('File import must be of type .json, .xlsx or .zip');
        return;
      }

      if (file.name.endsWith('.xlsx')) {
        this.startImportExcelFile(file);
        return;
      }

      try {
        event.target.value = '';

        this.importFileName = file.name;

        this.productJSON = {};
        this.showProductImportManager = true; // zip | json

        if (file.name.endsWith('.zip')) {
          json = await readZipImportedContent(file, 'Product-');
        } else {
          json = await readFromFile(file);
        }

        const contentJSON = json.content || json;
        contentJSON.rules = contentJSON.rules.map((r) =>
          this.regenerateGraphIds(r)
        );
        if (!contentJSON.type || !contentJSON.name) {
          Message.error(
            this.$t('product.import-wizard.invalid-product-to-verify')
          );
          this.showProductImportManager = false;
          return;
        }

        this.productJSON = contentJSON;
      } catch (error) {
        Message.error(this.$t('definition-lists.msg-invalid-json-content'));
        this.closeImportWizard();
      }
    },

    handleDrop(ev) {
      if (this.isReadOnlyMode) {
        return;
      }
      this.handleSelectedFile(ev, true);
    },
    async createNewProduct() {
      this.showProductCreationDialog = true;
    },
    async closeProductCreationDialog() {
      this.showProductCreationDialog = false;
    },
    async exportAs(extension = 'csv') {
      this.submittingExport = true;
      await this.exportProducts({
        type: this.productType,
        source: this.source
      });

      if (this.lastExport.length > 0) {
        let productTypeSheetname = '';
        let fields = ['name', 'updatedAt'];
        if (this.isOwner(this.tenant)) {
          fields = [...fields, 'groupName'];
        }
        switch (this.productType) {
          case productTypes.QUESTIONNAIRE:
            productTypeSheetname = 'QUESTIONNAIRE';
            fields = [...fields, 'subtype'];
            break;
          case productTypes.PRODUCT:
            productTypeSheetname = 'PRODUCT';
            fields = [...fields, 'author'];
            break;
          case productTypes.TEMPLATE:
            productTypeSheetname = 'TEMPLATE';
            fields = [...fields, 'author'];
            break;
          default:
            throw new Error('Invalid product type');
        }

        const formattedList = this.lastExport;

        exportToList(
          formattedList,
          fields,
          this.source
            ? `${productTypeSheetname}-${slugify(this.source.toUpperCase())}`
            : productTypeSheetname,
          extension
        );
      } else {
        Message.warning(this.$t('common.export.msg-no-data-for-export'));
      }
      this.submittingExport = false;
    },
    setDefaultTeam() {
      if (this.availableTeams && this.availableTeams.length) {
        const foundDefaultTeam = this.availableTeams.find(
          (t) => t.slug === this.tenant
        );
        this.importTeam = foundDefaultTeam
          ? foundDefaultTeam.id
          : this.availableTeams[0].id;
      }
    },
    closeTeamSelectorDialog() {
      this.showTeamSelectorDialog = false;
    },
    regenerateGraphIds(rule) {
      const result = rule;
      result.graph = this.replaceNodeId(result.graph);
      return result;
    },
    replaceNodeId(node) {
      return {
        ...node,
        id: uuid(),
        terms:
          node.terms &&
          node.terms.length &&
          node.terms.map((n) => this.replaceNodeId(n))
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.import-table .WARNING {
  background: #f9cd0b;
}

.import-table .ERROR {
  background: #f6c6ce;
}

.import-table .SUCCESS {
  background: rgb(161, 215, 106);
}
</style>
<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.headerBox {
  position: relative;
}

.row {
  position: relative;
}

.button-row {
  position: absolute;
  top: -25px;
  right: 25px;
}

.round-button {
  font-size: 25px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid $color-border;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin: 0;
  text-align: center;
  box-shadow: 0px 2px 2px 2px rgba(116, 116, 117, 0.05);
}

label.round-button {
  line-height: 1.8em;
  background-color: white;
}

.clear-button {
  background-color: transparent;
}

.hidden {
  display: none;
}

.table-head {
  color: #2b3034;
  opacity: 0.5;
  font-size: 16px;
  padding-bottom: 8px;
}

.flipY {
  transform: scaleY(-1);
}

label.round-button.flipY {
  box-shadow: 0px -2px 2px 2px rgba(0, 0, 143, 0.05);
}

.productRow:last-child {
  margin-bottom: 2em;
}

.sort-button {
  background: transparent;
  border: 0;
  color: grey;
}

.label {
  font-size: 0.8em;
  font-weight: 100;
  color: $color-gray;
}

.sourceFilter {
  position: relative;
  top: 5px;
}

.teamSelectorModal {
  text-align: center;

  .el-dialog {
    max-width: 500px;
  }
}

.inputSearch {
  width: 30vw;
}

.searchWithSrcFilter {
  gap: 0em 2em;
  margin: 1em 0em;
}

.title-table {
  font-size: 24px;
  font-weight: 700;
  color: $color-gray--action;
  margin: 1em 0 0;
}

.insuranceType-menu {
  text-transform: capitalize;
  background: linear-gradient(0deg, #fafafa, #fafafa),
    linear-gradient(0deg, #e5e5e5, #e5e5e5);
  padding: 0.75em 0em;
  border-bottom: 1px solid #e5e5e5;

  .el-tabs__nav>* {
    margin: 1em 0em;
  }
}

.insuranceType-menu>.el-tabs--card>.el-tabs__header .el-tabs__item {
  border: none !important;
}

.button-table-action {
  color: $color-axa-blue;
  border-radius: 4px;
}

.border-blue {
  border: 1px solid $color-axa-blue;
}

.pl-8 {
  padding-left: 5em;
}

.search-with-container {
  margin-bottom: 2em;
}

.title {
  position: relative;
  font-size: 150%;

  .icon {
    display: inline-block;
  }
}

.input {
  padding: 0.15em 0.7em;
  border-radius: 5px;
  background-color: #dedef1;
}

.config {
  position: relative;
  margin-left: 1em;
  padding: 0.15em 0.7em;
  border: solid 1px $color-active;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #f5f5f5;
}

.ui_link_input .input {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.b-dropdown.custom-scroll {
  .b-dropdown-form {
    &>div {
      display: grid;
      grid-template-columns: 1fr;
      height: fit-content;
      width: fit-content;
      max-height: 310px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
