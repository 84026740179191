<template>
  <div class="flex-grow-1 flex-shrink-1 d-flex overflow-hidden">

    <ComplexityChecker type="blocker"></ComplexityChecker>
    <input ref="fileUpload" type="file" hidden @input="handleSelectedFile" />

    <b-modal 
      id="gentestDialog" 
      v-model="displayGentestFlag" 
      :no-close-on-backdrop="true"
      title="Generate a test case">
      <div id="tutorialHowToScenario" class="tutorialHowToScenario">
        <div class="tutorialHowToScenario__title" @click="openTutorialBox">How to write a scenario
          <b-icon icon="chevron-down" class="tutorialHowToScenario__chevron closed" />
        </div>
        <div class="tutorialHowToScenario__content closed">
          Do not try to create a situation that answers to all of your product. Type a scenario, and the system will interpret your sentence to set the right variables.<br />
          <br />
          Make sure you include at the end of the scenario your expectation regarding the coverage status to the given situation with the current model.<br />
          <br />
          Example: Family taking a taxi from the highway as theis hydrogen can went out of fuel. The distance is from Velizy to the central Paris at Novotel Montparnasse. The taxi fare and towing of the car costed 1200 euros. The situation is covered.
        </div>
      </div>
      <b-form-group label="Scenario" label-for="testScenario">
        <b-form-textarea
          id="testScenario" 
          v-model="currentJob.prompt"
          :disabled="testGenerationStart"
          placeholder="Write your scenario" rows="5" @input="checkPromptValidity"></b-form-textarea>
          <span v-if="!promptValidity" class="error-no-name">Prompt text can't be empty</span>
          <div v-if="errorGenerateTest && promptValidity" class="error-no-name">
            <div class="error-icon">
              <i class="el-icon-warning"></i>
            </div>
            <div>
              {{ errorGenerateTest.message }}
            </div>
          </div>
      </b-form-group>
      <template #modal-header>
        <h5><b-icon-stars :class="spraklgStrs ? 'spraklgStrs' : null" />Generate a te<span class="shine" @click="makeStarsShinning">s</span>t case</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="cancelTest">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <template #modal-footer>
        <b-button class="align-left" size="sm" variant="outline-secondary" @click="cancelTest">
          CANCEL
        </b-button>
        <div v-if="testGenerationStart"><span class="fa fa-spinner" />Loading...<br /><span class="informative-text">(can take a few sec.)</span></div>
        <b-button
          size="sm" 
          :disabled="!promptValidity" 
          class="btn-CTA"
          :class="{ submitDisabled: !promptValidity, 'mr-2': true }" 
          variant="outline-indigo"
          @click="validate"
        >
          <b-icon-stars />&nbsp;&nbsp;{{$t('tests.generate')}}
        </b-button>
      </template>
    </b-modal>

    <div class="px-2 d-flex flex-column flex-grow-1 overflow-hidden">

      <UiToolbar class="p-2">
        <div class="generalStatus" data-cy="general-status">
          <div class="failed">
            {{ `${failedTestCount} ${$t('tests.failed')}` }}
          </div>
          <div class="success">
            {{ `${successTestCount} ${$t('tests.success')}` }}
          </div>
        </div>
        <div class="flex-spacer" />
        <b-input-group class="search-group">
          <b-form-input
            v-model="searchStr" 
            class="search-input" data-cy="tests-search"
            :placeholder="$t(`tests.search_tests`)" />
          <b-icon icon="search" class="search-input-icon"></b-icon>
        </b-input-group>
        <div class="flex-spacer" />
        <!--
        <b-button variant="primary" :disabled="!canSubmitNewTest" @click="generateNewTest">
          <b-icon-stars />&nbsp;&nbsp;{{ $t('tests.generate_a_test') }}
        </b-button>
        -->
        <b-button variant="primary" :disabled="isReadOnlyMode" @click="createNewTest">
          <i class="fas fa-plus mr-2"></i>{{ $t('tests.create_a_test') }}
        </b-button>

        <b-dropdown v-if="!isReadOnlyMode" variant="outline-primary" no-caret right>
          <template #button-content>
            <b-icon-three-dots />
          </template>

          <b-dropdown-item v-if="!noTestsAvailable" @click="handleGeneralAction('export')">
            <i class="fas fa-download"></i>
            {{ $t('tests.export_all') }}
          </b-dropdown-item>
          <b-dropdown-item @click="handleGeneralAction('import')">
            <i class="fas fa-upload"></i>
            {{ $t('tests.import') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="areAllSelected" @click="handleGeneralAction('deselectAll')">
            <i class="fas fa-check"></i>
            {{ $t('tests.deselect_all') }}
          </b-dropdown-item>
          <b-dropdown-item v-else @click="handleGeneralAction('selectAll')">
            <i class="fas fa-check"></i>
            {{ $t('tests.select_all') }}
          </b-dropdown-item>
          <b-dropdown-item @click="handleGeneralAction('deleteSelected')">
            <i class="fas fa-trash-alt"></i>
            {{ $t('tests.delete_selected') }}
          </b-dropdown-item>
        </b-dropdown>
      </UiToolbar>
      <UiLoadingArea :is-loading="stats.progress > 0 && !stats.error">
        <UiTable
          stripe 
          :data="sortedTests" 
          :default-sort="{ prop: sortField, order: sortOrder }" 
          class="ui_table_tests"
          row-key="id" 
          data-cy="tests-table" 
          @sort-change="onSort">
          <template #expand="{ row }">
            <div v-if="row.status !== 'pending'">
              <UiTable
:data="formatInnerData(row)" variant="padded" class="innerTable" :row-class-name="isFailedTest"
                data-cy="results-table">
                <el-table-column prop="description" :label="$t('tests.output.description')"></el-table-column>
                <el-table-column prop="expected" :label="$t('tests.output.expected')"></el-table-column>
                <el-table-column prop="actual" :label="$t('tests.output.actual')"></el-table-column>
              </UiTable>
            </div>
            <div v-else class="padding-left-40">
              <span class="fa fa-spinner" /> {{ $t('tests.loading') }}
            </div>
          </template>

          <el-table-column label="" width="50" align="center" data-cy="test-selector">
            <template #default="{ row }">
              <div class="testSelector">
                <input v-model="selectedTests" type="checkbox" :value="row.id" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
prop="status" label="Status" sortable="custom" width="90" align="center"
            data-cy="test-status">
            <template #default="{ row }">
              <div class="testStatus" :class="{ success: row.status === true, pending: row.status === 'pending' }"></div>
              <span v-if="row.status === 'pending'" class="fa fa-spinner" /> 
            </template>
          </el-table-column>

          <el-table-column prop="name" label="Name" sortable="custom" data-cy="test-name">
            <template #default="{ row }">
              <div>
                <div class="testName" @click="handleActionCommand(`edit-${row.id}`)"><b-icon-stars v-if="isGeneratedTest(row)" /> {{ row.name }} {{ row.status === 'pending' ? '- processing, please wait.' : '' }}</div>
                <div v-if= "row.description" class="testDescription">
                  {{ row.description }}
                </div>
              </div>
            </template>
            
          </el-table-column>

          <el-table-column prop="" label="" width="70" data-cy="test-actions">
            <template #default="{ row }">
              <UiThreeDotsDropDown
              v-if="row.status !== 'PENDING'"
:items="getTestActionItems(row)" data-cy="test-actions-dropdown"
                @command="(command) => handleActionCommand(command)">
              </UiThreeDotsDropDown>
            </template>
          </el-table-column>
        </UiTable>
      </UiLoadingArea>
    </div>
    <UiDialog
      id="alert" 
      :title="alert.title" 
      :visible="displayAlertModal" 
      :confirm-label="confirmLabel"
      :cancel-label="cancelLabel"
      confirm-variant="primary"
      cancel-variant="secondary"
      :hide-cancel="!alert.cancel"
      :hide-confirm="!alert.ok"
      @cancel="cancelAction"
      @confirm="confirmAction"
      >
      <template #dialog-content>
        {{ alert.message }}
      </template>
    </UiDialog>
  </div>
</template>

<script>
const INTERVAL_REFRESH = 5000;
import { mapState, mapGetters, mapActions } from 'vuex';
import * as api from '../api';
import { sortByFieldname } from '../helpers';
import ComplexityChecker from '../components/Product/ComplexityChecker.vue';
import { getTestgenDimensionsFormatted, getTestgenEndorsementsFormatted, getTestgenInputVariablesFormatted, getTestgenOuputVariablesFormatted } from '@/util';

const initialCurrentJob = { id:undefined, status: undefined, prompt: undefined };

export default {
  name: 'ProductTests',
  components: {
    ComplexityChecker
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    searchStr: '',
    sortField: 'id',
    sortOrder: 'descending',
    selectedTests: [],
    errorNoPrompt: false,
    errorGenerateTest: false,
    canSubmitNewTest: true,
    testGenerationStart: false,
    checkPendingJob: 0,
    currentJob: {},
    spraklgStrs: false,
    testIdToDelete: null,
    alert: {
      title: null,
      message: null,
      variant: 'default',
      ok: {
        action: null,
        label: null
      },
      cancel: {
        action: null,
        label: null
      },
    },
    displayGentestFlag: false,
    displayAlertFlag: false
  }),
  computed: {
    ...mapGetters('product', ['isReadOnly']),
    ...mapState('productTests', ['tests', 'stats', 'isLoading']),
    ...mapState('auth', ['tenant']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('route', {
      versionId: (state) => state.query.v
    }),
    emptySearch() {
      return !this.searchStr || this.searchStr.trim().length === 0;
    },
    /**
     * Sorting fields
     */
    sortedTests() {
      let result = [];
      // Check for pending job. If there is one, add it to the sorting fields to display it, while it's processed.
      let tests = [...this.tests, this.currentJob];
      tests = tests.filter((test) => !!test && test.id);
      // Sort fields
      if (this.sortField === 'id' && this.sortOrder === 'descending') {
        result = tests.reverse();
      } else {
        result = sortByFieldname(
          tests,
          this.sortField,
          this.sortOrder
        );
      }
      if (this.emptySearch) {
        return result;
      }
      return result.filter((t) =>
        t.name.toLowerCase().includes(this.searchStr.toLowerCase())
      );
    },
    failedTestCount() {
      return this.stats.total - this.stats.success;
    },
    successTestCount() {
      return this.stats.success;
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    noTestsAvailable() {
      return (!this.sortedTests || !this.sortedTests.length) && !this.isLoading;
    },
    areAllSelected() {
      return this.tests.length === this.selectedTests.length;
    },
    displayAlertModal() {
      return this.displayAlertFlag;
    },
    confirmLabel() {
      return this.alert?.ok?.label;
    },
    cancelLabel() {
      return this.alert?.cancel?.label;
    },
    /**
     * Check for test prompt validity
     * 1) if the current job prompt is not set, so no user input yet.
     * 2) as soon as there is a user input on the field, the prompt is set with and empty string.
     *  checking if the string is more than 0 character.
     */
    promptValidity() {
      if ( this.currentJob?.prompt?.length === 0) {
        return false;
      }
      if (
        typeof this.currentJob?.prompt === "undefined" || 
        (this.currentJob?.prompt && this.currentJob?.prompt?.length > 0)
      ) {
        return true;
      }
      return true;
    },
  },
  /**
   * On mounted, check if there is a pending job inside the local storage.
   * If this is the case, then, we can handle it.
   * A small check for kind of empty local storage is used to avoid currentJob to be empty.
   */
  async mounted() {
    try {
      const savedPendingJob = JSON.parse(window.localStorage.getItem('atjobIds'));
      if ((savedPendingJob instanceof Array && savedPendingJob.length === 0)) {
        if (savedPendingJob.length === 0) {
          window.localStorage.removeItem('atjobIds');
        }
        // Proceed to treat the savedPending job.
        this.currentJob = this.formatPendingJob(savedPendingJob);
        this.jobCreationProcess();
      }
      // Check for pending jobs or jobs to delete (which are failed or succeded)
      await this.checkForDuplicateOrFailedJob();
      this.checkForPendingJob();
    } catch (e){
      console.error(e);
    }

  },
  methods: {
    ...mapActions('productTests', ['fetch']),
    // On validation of the LLM modal, block the user input and launch the test genration
    validate() {
      this.canSubmitNewTest = false;
      this.generateTest();
    },
    displayAlert(alertOptions) {
      this.alert = { ...alertOptions };
      this.displayAlertFlag = true;
    },
    /**
     * mandatory to use a method because the @input can't check properly the value of promptvalidity if it calls directly the computed
     */
    checkPromptValidity() {
      this.errorGenerateTest = false;
      return this.promptValidity;
    },
    confirmAction() {
      this.alert.ok.action();
    },
    cancelAction() {
      this.alert.cancel.action();
    },
    /**
     * By precaution, and to avoid spamming the DB with useless jobs, we are checking if there is 
     * sucessed jobs or failed one. If it's the case, we delete them from the DB.
     */
    async checkForDuplicateOrFailedJob() {
      const pendingJobs = await api.retrieveTestJobsqueue(this.product.id);
      let endedJobs = pendingJobs.filter((job) => job.status !== 'pending' && job.status !== 'running');
      endedJobs = endedJobs.map((job) => job.jobId);
      if (endedJobs.length > 0) {
        await api.deletePendingJobs(endedJobs, this.product.id);
        this.refresh();
      }
      return true;
    },
    /**
     * Deleting pending job ginving a currentJob id.
     */
    async cancelTest() {
      if (this.currentJob.prompt) {
        this.displayAlert({
          variant: 'warning',
          title: 'Cancel current test creation?',
          message: 'Do you really want to cancel the current test creation?',
          ok: {
            action: () => {
              this.errorGenerateTest = false;
              this.displayAlertFlag = false;
              this.displayGentestFlag = false;
              this.currentJob = {};
              this.checkForDuplicateOrFailedJob();
            },
            label: 'Yes, I want to cancel current job.'
          },
          cancel: {
            action: () => { 
              this.displayAlertFlag = false;
            },
            label: `No, I don't want to cancel current job`
          }
        })
      } else {
        this.displayGentestFlag = false;
      }
      this.canSubmitNewTest = true;
      this.refresh();
    },
    async validateDeleteTest() {
      try {
        await api.deleteTest(this.testIdToDelete);
        this.testIdToDelete = null;
        this.refresh();
      } catch (err) {
        return;
      }
    },
    isGeneratedTest(row) {
      return row.pendingTestGen || (row.definition && row.definition.testgen && row.definition.testgen.origin === 'copilot');
    },
    getRowStatus(row) {
      return row === 'pending' ? 'pending': row;
    },
    openTutorialBox () {
      [...document.getElementsByClassName('tutorialHowToScenario__chevron')][0].classList.toggle('closed');
      [...document.getElementsByClassName('tutorialHowToScenario__content')][0].classList.toggle('closed');
    },
    /**
     * test generation process.
     * Generate a new job for a test.
     */
    async generateTest() {
      this.errorGenerateTest = false;
      this.testGenerationStart = true;
      if (this.currentJob.prompt.length === 0 || this.currentJob.prompt.trim().length === 0) {
        this.errorNoPrompt = true;
        return false;
      }
      try {
        // In the other case, we can continue
        const { data } = await api.generateTest(this.product.id, this.currentJob.prompt, this.tenant);
        this.currentJob = {
          ...this.currentJob,
          id: data.data.jobId,
          name: data.data.testName,
          definition: [],
          pendingTestGen: true,
          status: 'pending',
          timestamp: new Date().getTime(),
        };
        // If everything goes well, then proceed to the test creation.
        await this.jobCreationProcess();
        return;
      } catch(error) {
        // translate timeout message / API error explanation / error message
        if (error?.message.includes('timeout')){
          this.errorGenerateTest = { ...error, message: this.$t('tests.generate_test_timeout') }; 
        }
        else {
          // .explanation and .error are expected responses from api, .message and .code are standard props of js exception obj
          this.errorGenerateTest = { ...error, message: this.$t('tests.generate_test_error', [error?.explanation || error?.error || error?.message || error?.code]) };
        }
        this.testGenerationStart = false;
      }
    },
    /**
     * check if there is a pending job. I there is one, then check the status : if it's pending, then recall the api to check it's status.
     * If the status is success, then proceed to add it to the current list.
     * Removing the pending job from the local stack.
     */
    async jobCreationProcess () {
      try {
        if (this.currentJob.id) {

          const pendingJobs = await api.retrieveTestJobsqueue(this.product.id);
          let job = {...this.currentJob && pendingJobs.find((jobInQueue) => jobInQueue.jobId === this.currentJob.id)};
          job = this.formatPendingJob(job);

          if (job && job.id) {
            // if status is success
            if(job.status === "success"){
              window.clearInterval(this.checkPendingJob);
              const jobDetails = await api.retrieveTestGenValues(job.id);
              const dimensionsFormatted = getTestgenDimensionsFormatted(jobDetails.dimensions);
              const inputVariablesFormatted = getTestgenInputVariablesFormatted(jobDetails.input_variables);
              const outpuFormatted = getTestgenOuputVariablesFormatted(jobDetails.assumptions);
              const endorsementsFormatted = getTestgenEndorsementsFormatted(jobDetails.endorsements);
              // Set the test definition to be saved.
              const definition =   {
                input: {...inputVariablesFormatted, ...dimensionsFormatted},
                output: outpuFormatted,
                endorsements: endorsementsFormatted,
                testgen: {
                  id: job.id,
                  name: job.name,
                  prompt: jobDetails.prompt,
                  input_variables: jobDetails.input_variables,
                  dimensions: jobDetails.dimensions,
                  origin: "copilot"
                }
              }
              // Send the payload to the API
              const response = await api.createTestGen(job.id, this.product.id, job.name, definition);
              const { id } = response?.data;
              // Delete the current job from the DB queue.
              await api.deletePendingJobs([this.currentJob.id], this.product.id);
              window.localStorage.removeItem('atjobIds');
              // Reinitialization of the currentjob.
              this.currentJob = { ...initialCurrentJob };
              this.refresh();
              this.displayGentestFlag = false;
              this.testGenerationStart = false;
              this.canSubmitNewTest = true;
              // Goes to the test page.
              this.$router.push({
                path: `tests/${id}`,
                query: this.$route.query
              });
            }

            // If the test is in pending or running status, we call back this function to check its status.
            if (job.status === 'pending' || job.status === 'running') {
              this.currentJob.timestamp = new Date().getTime();
              window.clearInterval(this.checkPendingJob);
              this.checkForPendingJob();
              this.refresh();
            }

            // If status is failed
            if (job.status === 'failed') {

              window.clearInterval(this.checkPendingJob);
              window.localStorage.removeItem('atjobIds');
              this.testGenerationStart = false;
              this.canSubmitNewTest = true;
              this.checkForDuplicateOrFailedJob();
              this.refresh();

              this.displayAlert({
                title: 'Job failed',
                message: "The job has encountered an error and can't be processed. Please try again.",
                ok: {
                  action: async () => {
                    this.displayAlertFlag = false;
                  },
                  label: 'OK'
                }
              })
            }

          } else {
            // No pending jobs from the DB. Prevently delete the local job if there is one to avoid duplication.
            // deleting pending job locally
            window.localStorage.removeItem('atjobIds');
            this.currentJob = {};
          }
        } else {
          if (this.checkPendingJob) {
            window.clearInterval(this.checkPendingJob);
          }
        }
      } catch(error) {
        console.error(error);
      }
    },
    /**
     * Formating the job from database to avoid mismatches between us and the API 
     * (API job id id jobId, our jod id is job.id...)
     */
    formatPendingJob(job) {
      return {
        id: job.jobId || job.id,
        name: job.testName || job.name,
        status: job.status.toLowerCase()
      };
    },
    /**
     * Interval to check pending jobs.
     */
    checkForPendingJob() {
      this.checkPendingJob = window.setInterval(this.jobCreationProcess, INTERVAL_REFRESH);
    },  
    formatInnerData(row) {
      const result = [];
      const omit = (key, { [key]: _, ...obj }) => obj;
      const outputs = omit('limits', row.definition.output);
      Object.entries(outputs).forEach((elm) => {
        result.push({
          description: elm[0],
          expected: `${elm[1]}`,
          actual: `${row.results[elm[0]]}`
        });
      });
      return result;
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    getTestActionItems(row) {
      let actions = [
        {
          command: `edit-${row.id}`,
          label: this.isReadOnlyMode
            ? this.$t('tests.view_test')
            : this.$t('tests.edit_test'),
          class: 'ui_menu_edit',
          icon: this.isReadOnlyMode ? 'el-icon-view' : 'icon-pencil-1',
          disabled: false
        }
      ];

      if (!this.isReadOnlyMode) {
        actions = [
          ...actions,
          {
            command: `duplicate-${row.id}`,
            label: this.$t('tests.copy_test'),
            class: 'ui_menu_edit',
            icon: 'far fa-clone',
            disabled: false
          },
          {
            command: `delete-${row.id}`,
            label: this.$t('tests.delete_test'),
            class: 'ui_menu_delete',
            icon: 'far fa-trash-alt',
            variant: 'danger',
            disabled: false
          }
        ];
      }

      return actions;
    },
    isFailedTest({ row }) {
      if (row.actual !== row.expected) return 'failedTest';
      return '';
    },
    generateNewTest() {
      this.displayAlertFlag = false;
      this.displayGentestFlag = true;
    },
    createNewTest() {
      this.$router.push({
        path: 'tests/new',
        query: this.$route.query
      });
    },
    handleActionCommand(command) {
      const [action, testId] = command.split('-');
      switch (action) {
        case 'edit':
          this.$router.push({
            path: `tests/${testId}`,
            query: this.$route.query
          });
          break;
        case 'duplicate':
          this.$router.push({
            path: 'tests/new',
            query: { ...this.$route.query, copy: testId }
          });
          break;
        case 'delete':
          this.testIdToDelete = testId;
          this.displayAlert({
            title: this.$t('tests.delete_selected'),
            message: this.$t('tests.delete_test_question'),
            ok: {
              action: () => { 
                this.validateDeleteTest(); 
                this.displayAlertFlag = false; 
              },
              label: this.$t('tests.yes_delete_test')
            },
            cancel: {
              action: () => { 
                this.displayAlertFlag = false;
              },
              label: this.$t('tests.no_delete')
            }
          })
          break;
        default:
          break;
      }
    },
    async handleGeneralAction(action) {
      switch (action) {
        case 'export':
          this.exportTests();
          break;
        case 'import':
          this.$refs.fileUpload.click();
          break;
        case 'selectAll': {
          this.selectedTests = [];
          const filteredTests = this.emptySearch
            ? this.tests
            : this.tests.filter((t) =>
              t.name.toLowerCase().includes(this.searchStr.toLowerCase())
            );
          filteredTests.forEach((test) => {
            this.selectedTests.push(test.id);
          });
          break;
        }
        case 'deselectAll':
          this.selectedTests = [];
          break;
        case 'deleteSelected':
          if (this.selectedTests.length === 0) {
            this.displayAlert({
              title: 'Error',
              message: this.$t('tests.no_test_selected'),
              ok: { action: () => {
                this.displayAlertFlag = false;
              }, 
              label: 'OK' }
            });
            return;
          }
          this.displayAlert({
            title: 'Delete all selected test',
            message: 'Do you want to delete the selected tests?',
            ok: {
              action: async () => {
                await api.deleteTests(this.selectedTests);
                this.refresh();
                this.displayAlertFlag = false;
              },
              label: 'OK'
            },
            cancel: {
              action: () => {
                this.displayAlertFlag = false;
              },
              label: 'Cancel'
            }
          });
          break;
        default:
          break;
      }
    },
    refresh() {
      this.fetch();
    },
    async importTests(file) {
      const { data } = await api.importByType(this.product.id, 'tests', file);
      if (!data.ok) {
        this.displayAlert({ 
          title: 'Error',
          message: `${this.$t('tests.import_error'), 
            { message: data.message }}`, 
          cancel: { 
            action: () => { this.displayAlertFlag = false; },
            label: 'OK' 
          }
        })
        return;
      }

      const { imported, rejected } = data;
      const title = this.$t('tests.import');
      let message = '';
      if (imported > 0 && rejected.length === 0) {
        message = `${this.$t('tests.import_message_successful', { nb: imported })}`;
      } else if (imported > 0 && rejected.length > 0) {
        message = `${this.$t('tests.import_message_warn', { nb: imported, nbRejected: rejected.length })}`;
      } else if (imported === 0 && rejected.length > 0) {
        message = `${this.$t('tests.import_message_error'), { rejected: rejected.length } }`;
      } else {
        message = this.$t('tests.import_message_unknownstate');
      }
      this.displayAlert({ 
        title,
        message,
        cancel: { 
          action: () => { this.displayAlertFlag = false; },
          label: 'OK' 
        } 
      });
      this.refresh();
    },
    handleSelectedFile(ev) {
      const [file] = ev.target.files;
      this.importTests(file);
    },
    exportTests() {
      api.exportTests(this.product.id, 'tests');
    },
    makeStarsShinning() {
      this.spraklgStrs = !this.spraklgStrs;
    },
  }
};
</script>


<style lang="scss">

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes colorpulsation {
  0% {
    color: white;
    scale: 95%;
  }
  50% {
    color: red;
    scale: 100%;
  }
  100% {
    color: yellow;
    scale: 95%;
  }
}

.modal-footer {
  justify-content: space-between;
}

.fa-spinner {
  animation: spinner 1.5s linear infinite;
  margin-right: .5rem;
}

.shine {
  transition: all .3s;
  &:hover {
    cursor:help;
    color: yellow;
  }
}

.spraklgStrs {
  animation: colorpulsation 1s linear infinite;
}

</style>
<style lang="scss" scoped>
@import "@axatechlab/assets/scss/variables";

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.informative-text {
  font-size: .8rem;
}

#debug ul{
  line-height-step: 0.5;
}

#debug ul li{
  line-height: 0.9;
}

.tutorialHowToScenario {
  border: 1px solid $color-silver;
  padding: 10px;
  &__title {
  }
  &__chevron {
    transform: rotateX(180deg);
    transition: all .3s;
    float: right;
    animation: rotating .3s linear;
    &.closed {
      transform: rotateY(0);
      animation: rotating .3s linear;
    }
  }
  &__content {
    border-top: 1px solid $color-silver;
    transition: all .3s;
    height: 320px;
    opacity: 1;
    padding-top: 10px;
    margin-top: 10px;
    overflow: scroll-y;
    z-index: 0;
    overflow: visible;
    &.closed {
      margin-top: 0;
      padding-top: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }
}

.job-failed {
  color: #f92929;
}

.job-pending {
  color: #00008f;
}

.submitDisabled {
  background-color: lightgray;
  border: 1px solid lightgray;
  color: gray;
  cursor: not-allowed;
}


$redStatus: #e7473a;
$greenStatus: #17da99;
$orangeStatus: #ffa200;
$lightGrey: #fefefe;

.UiToolbar {
  margin-top: 0;
  align-items: center;

  .hidden {
    display: none;
  }

  & ::v-deep .createButton {
    .el-button {
      font-weight: 400;
      padding: 6px 15px;
      text-transform: none;
      background-color: #3139be;
      color: $lightGrey;
      border: none;

      i {
        border-color: $lightGrey;
      }
    }
  }

  .actionsDropdown {
    .el-button {
      border-radius: 5px;
      background-color: $lightGrey;
      color: #464dc5;
      border-color: #cacaf6;
      font-weight: 400;
      padding: 10px 15px;
      text-transform: none;
    }
  }
}

.UiTable.ui_table_tests {
  & ::v-deep .el-table {
    tr {
      td:first-child {
        border-left: 1px solid #ebeef5;
      }

      td:last-child {
        border-right: 1px solid #ebeef5;
      }
    }

    .testName {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .testSelector {
      input[type='checkbox'] {
        vertical-align: middle;
        transform: scale(1.4);
      }
    }

    .testStatus {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin-left: 25%;
      background-color: $redStatus;

      &.success {
        background-color: $greenStatus;
      }

      &.pending {
        background-color: $orangeStatus;
      }
    }

    .testDescription {
      font-style: italic;
      font-size: .8rem;
    }

    .UiTable.innerTable {
      max-width: 600px;
      margin: 0 auto;

      tr {
        &.failedTest {
          background-color: lighten($redStatus, 30%);
        }

        &:hover>td {
          background-color: initial !important;
        }

        td {
          border-left: none;
          border-right: none;

          .cell {
            min-height: 25px;
          }
        }
      }
    }
  }
}

.generalStatus {
  display: flex;
  gap: 8px;
  font-weight: bold;

  div {
    white-space: nowrap;
    vertical-align: middle;

    &:before {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      content: ' ';
    }

    &.failed::before {
      background-color: $redStatus;
    }

    &.success::before {
      background-color: $greenStatus;
    }
  }
}

.loading-spinner {
  display: flex;
  align-items: center;

  .loading-spinner-animation {
    width: 30px;
    height: 30px;
  }
}

.hidden {
  display: none;
}

.error-no-name {
  display: flex;
  color: $color-red;
}

.error-icon {
  margin: auto .11rem;
}
</style>
