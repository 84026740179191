<template>
  <div v-if="current || mode === 'form'" class="question">
    <div class="claim-handler-select w-100" :class="`${mode}-combo`">
      <el-select
        ref="input"
        v-model="value"
        :popper-append-to-body="mode !== 'widget'"
        :data-cy="`input-${question.id}`"
        filterable
        class="claim-handler-select-input w-100"
        popper-class="dropdown"
        :collapse-tags="['xs'].includes(elq)"
        :remote-method="setInput"
        placeholder="Search..."
        :clearable="mode === 'form'"
        :disabled="question.loading"
        :multiple="question.allowMultiplePerClaim"
        @clear="submitClear"
        @input="$emit('show')"
        @change="
          $emit('show');
          setValue(value);
          submitIfNotMultiple();
        "
        @keydown.enter.exact.native.prevent.stop="
          $emit('show');
          setValue(value);
          submit(true);
        "
        @remove-tag="
          setValue(value);
          submit();
        "
        @keydown.tab.native.capture="captureTab($event)"
        @focus="$emit('focus')"
      >
        <i
          v-if="question.loading"
          slot="prefix"
          class="el-icon-loading el-input__icon"
        ></i>
        <template slot="suffix">
          <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
        </template>
        <template v-if="mode === 'form'">
          <el-option
            v-for="(answer, index) in filteredAnswers"
            :key="`${answer.id}-${index}`"
            :value="answer.id"
            :label="answer.displayName"
            :data-cy="`answer-${question.id}-${answer.id}`"
          >
          </el-option>
        </template>
        <template v-else>
          <el-option-group
            v-if="possibleAnswers.length"
            key="possible"
            :label="onlyPossible ? '' : 'Possible answers'"
          >
            <el-option
              v-for="(answer, index) in possibleAnswers"
              :key="`${answer.id}-${index}`"
              :value="answer.id"
              :label="answer.displayName"
              :data-cy="`answer-${question.id}-${answer.id}`"
            >
            </el-option>
          </el-option-group>
          <el-option-group
            v-if="otherAnswers.length && !onlyPossible"
            key="irrelevant"
            label="Other answers"
          >
            <el-option
              v-for="(answer, index) in otherAnswers"
              :key="`${answer.id}-${index}`"
              :value="answer.id"
              :label="answer.displayName"
              :data-cy="`answer-${question.id}-${answer.id}`"
            >
            </el-option>
          </el-option-group>
        </template>
      </el-select>
    </div>
  </div>
</template>
<script>
import captureTabMixin from './mixins/captureTabMixin';
import inputMixin from './mixins/inputMixin';

export default {
  name: 'InputComboComponent',
  mixins: [captureTabMixin, inputMixin],
  inject: ['onPossible'],
  props: {
    elq: { type: String, default: undefined }
  },
  data() {
    return {
      input: '',
      onlyPossible: this.onPossible.val
    };
  },
  computed: {
    filteredAnswers() {
      if (this.input !== '') {
        return this.question.answers.filter((answer) =>
          this.input.split(' ').every((i) => answer.contains(i))
        );
      }
      return this.question.answers;
    },
    possibleAnswers() {
      return this.filteredAnswers.filter((a) =>
        this.question.possibleAnswers.includes(a.id)
      );
    },
    otherAnswers() {
      return this.filteredAnswers.filter(
        (a) => !this.question.possibleAnswers.includes(a.id)
      );
    }
  },
  watch: {
    value(newVal, newOld) {
      // biggest workaround probably on this file mais il fait la job
      if(newVal != undefined){
        this.value = newVal;
      } else {
        this.value = newOld;
      }
    }
  },
  methods: {
    async focusInput() {
      await this.$nextTick();
      if (this.$refs.input) {
        this.$refs.input.$el.querySelector('input').focus({
          preventScroll: true
        });
      }
    },
    setInput(val) {
      this.input = val;
    },
    submitIfNotMultiple() {
      if (!this.question.allowMultiplePerClaim) {
        this.submit();
      }
    }
  }
};
</script>

<style lang="scss">
.question {
  display: flex;
}
.claim-handler-select {
  overflow: hidden;
  max-width: 100%;
  min-height: 42px;
  margin-top: 3px;

  &.widget-combo {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: inset 1px rgb(64, 158, 255);
    max-width: 100%;

    .el-select.claim-handler-select-input {
      overflow: hidden;
      min-width: inherit !important;
      width: 100%;
      margin-bottom: -15px;
      max-height: 100%;
      .el-input__inner {
        border: none;
      }
      .el-select__tags {
        top: 8px;
        transform: none;
      }
      .dropdown {
        top: 0 !important;
        position: relative !important;
        width: 100%;
        max-width: 100%;
        .el-scrollbar {
          height: calc(30vh + 15px);
          .el-select-dropdown__wrap {
            min-height: 100%;
            width: calc(100% + 15px);
            .el-select-dropdown__item {
              max-width: 100%;
            }
          }
        }
      }
      .dropdown.el-select-dropdown {
        box-shadow: none;
        border: none;
        margin: 0;
        .popper__arrow {
          display: none;
        }
      }
    }
  }
}
.dropdown {
  font-family: var(--font-main);
}
</style>
