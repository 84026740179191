<template>
    <div class="version-dropdown">
        <b-popover target="dropdown-1" custom-class="version-popover" triggers="click blur" placement="bottom"
            @show="isPopoverOpen = true" @hide="isPopoverOpen = false">
            <div v-for="(version, index) in displayedVersions" :key="version.versionAsString || 'Initial'">
                <template v-if="hasMoreVersions || (index < displayedVersions.length - 1)">
                    <b-button variant="outline-light" block class="version-item border-0" @click="switchCurrentActiveRelease(version.id, version.version, product.id, {
                        updateRoute: true,
                    })">
                        <b-tag pill variant="light" no-remove>v {{ version.versionAsString || 'Initial' }}</b-tag>
                        <div>
                            <PublicationStatusTag :status="version.publicationStatus" />
                            <b-icon v-if="version.versionAsString === currentVersion" icon="check-circle-fill"
                                variant="dark" class="ml-2" />
                        </div>
                    </b-button>
                    <hr />
                </template>
            </div>
            <b-button v-if="hasMoreVersions" :to="{ name: 'product-releases' }" variant="outline-light" block
                class="border-0 text-left">
                <small class="text-black">{{ $t('version.more') }} ({{ availableVersions.length }} versions)</small>
            </b-button>
            <b-button v-else size="sm" variant="outline-light" block class="version-item border-0" @click="switchCurrentActiveRelease(initialVersion.id, initialVersion.version, product.id, {
                updateRoute: true,
            })">
                <b-tag pill variant="light" no-remove>{{ $t('version.initial') }}</b-tag>
                <div>
                    <b-icon v-if="currentVersion === 'initial'" icon="check-circle-fill" variant="dark" class="ml-2" />
                </div>
            </b-button>
            <hr />
            <b-button :to="{ name: 'product-releases' }" variant="outline-light" block class="border-0 text-left">
                <small class="text-black">{{ $t('version.all') }}</small>
            </b-button>
        </b-popover>
        <b-button size="sm" id="dropdown-1" class="version-label text-dark border-new-gray overflow-hidden px-2 py-05"
            variant="outline-light" v-if="!hasNotReleases">
            <span>{{ product.name }}</span>
            <b-tag pill variant="light" no-remove>
                <span v-if="currentVersion !== 'initial'">v</span>
                <span class="text-capitalize">
                    {{ currentVersion }}
                </span>
            </b-tag>
            <PublicationStatusTag v-if="currentStatus" :status="currentStatus" />
            <slot />
            <b-icon :icon="isPopoverOpen ? 'chevron-up' : 'chevron-down'" font-scale="0.8"></b-icon>

        </b-button>
        <span v-if="hasNotReleases" id="product_name">{{ product.name }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ProductReleasesMixin from './ProductReleases/ProductReleasesMixin';
import PublicationStatusTag from './PublicationStatusTag.vue';
import productTypes from '@/const/product';
import productImporters from '@/const/product.importers';

const MAX_VERSIONS_TO_SHOW = 5;

export default {
    name: 'VersionDropdown',
    mixins: [ProductReleasesMixin],
    components: {
        PublicationStatusTag
    },
    props: {
        currentVersion: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isPopoverOpen: false
        };
    },
    computed: {
        ...mapState('productRelease', ['releases']),
        ...mapState('product', ['product']),
        availableVersions() {
            return this.releases.map((release) => ({
                versionAsString: release.versionAsString,
                publicationStatus: this.getPublicationStatus(release.publicationStatus, release.lambdaPublished),
                id: release.id,
                version: release.version,
                lambdaPublished: release.lambdaPublished
            }));
        },
        currentStatus() {
            const currentRelease = this.availableVersions.find(v => v.versionAsString === this.currentVersion);
            return currentRelease ?
                this.getPublicationStatus(currentRelease.publicationStatus, currentRelease.lambdaPublished) :
                undefined;
        },
        initialVersion() {
            return this.availableVersions[this.availableVersions.length - 1];
        },
        displayedVersions() {
            return this.availableVersions.slice(0, MAX_VERSIONS_TO_SHOW);
        },
        hasMoreVersions() {
            return this.availableVersions.length > MAX_VERSIONS_TO_SHOW;
        },
        hasNotReleases() {
            return this.product.type === productTypes.TEMPLATE || this.product.source === productImporters.IMPORT_LIGHT;
        }
    },
    methods: {
        handleCommand(version) {
            this.$emit('switch-version', version);
        },
        isLambdaError(lambdaPublished) {
            return lambdaPublished && lambdaPublished.status === 'Failed';
        },
        isLambdaPending(lambdaPublished) {
            return lambdaPublished && lambdaPublished.status === 'Pending';
        },
        getPublicationStatus(publicationStatus, lambdaPublished) {
            if (publicationStatus === 'PRODUCTION' && this.isLambdaError(lambdaPublished))
                return 'PRODUCTION-ERROR';
            if (publicationStatus === 'PRODUCTION' && this.isLambdaPending(lambdaPublished))
                return 'PRODUCTION-PENDING';
            return publicationStatus;
        },
    }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.version-dropdown {
    display: inline-block;
    margin-left: 0.5rem;
}

.version-label {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.version-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

hr {
    margin: 0;
}

.text-black {
    color: #000;
}

.py-05 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

.border-new-gray {
    border-color: $cc-grey--200;
}
</style>

<style>
.version-popover {
    min-width: 320px;

    .popover-body {
        padding: 0;
    }
}

.version-dropdown,
.version-popover {
    .b-form-tag {
        border: solid 1px #ccc;
    }
}
</style>