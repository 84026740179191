const MISSING_PROPERTY_IN_CSP_LABEL = 'MISSING-PROPERTY-IN-CSP';

/**
 * @typedef {{
 *   name: string;
 * }} Property
 */

/**
 * @typedef {{
 *  showOnlyMissingCsp: boolean;
 *  showOnlyMissingUnused: boolean;
 *  productValidations: Record<number, { errors: { property: string; code: string; }[] }>;
 *  productId: number;
 *  unusedProperties: Record<string, string[]>;
 * }} ConfigurationFiltersContext
 */

/**
 * @param {Property} property Property to filter
 * @param {ConfigurationFiltersContext} context Context of the filter
 * @returns {boolean}
 */
export const filterMissingCSP = (property, context) => {
  if (!context.showOnlyMissingCsp) {
    return true;
  }

  const { errors } = context.productValidations[context.productId];
  const propertyInError = errors.find((e) => e.property === property.name);
  return (
    !!propertyInError &&
    propertyInError.code === MISSING_PROPERTY_IN_CSP_LABEL
  );
}

/**
 * @param {Property} property Property to filter
 * @param {ConfigurationFiltersContext} context Context of the filter
 * @returns {boolean}
 */
export const filterMissingUnused = (property, context) => {
  if (!context.showOnlyMissingUnused) {
    return true;
  }
  let found = false;
  Object
    .keys(context.unusedProperties)
    .forEach((unusedProperty) => {
      if (context.unusedProperties[unusedProperty].includes(property.name)) {
        found = true;
      }
    });
  return found;
};