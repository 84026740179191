<template>
  <div v-selector :class="overrideClass" class="text-right back-button">
    <router-link :to="backToWithQuery">
      <span data-cy="back-button">
        <i class="icon-keyboard-arrow-left"></i>
        {{ text }}
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    text: {
      type: [String, Object],
      default: 'Back'
    },
    overrideClass: {
      type: [String, Object],
      default: ''
    },
    backTo: {
      type: [String, Object],
      default: '.'
    }
  },
  computed: {
    backToWithQuery() {
      if (this.backTo !== '.') return this.backTo;

      const { version } = this.$route.query;
      return version ? { path: '.', query: { version } } : '.';
    }
  }
};
</script>

<style lang="scss" scoped>
.back-button {
  position: absolute;
  top: 0;
  left: 16px;
}
</style>
