/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const getDimensionsFromRule = (rule, dimensions, name, DIMENSIONS) => {
  if (rule.type === 'operator') {
    (rule.terms||[]).forEach((child) => {
      getDimensionsFromRule(child, dimensions, name, DIMENSIONS);
    });
    return;
  }
  if (rule.type === 'insuranceOutput' && rule.scopes && rule.scopes.target) {
    if (rule.scopes.target.test === 'matchQuery') {
      getDimensionsFromRule(
        { key: rule.scopes.target.args[0], value: rule.scopes.target.args[1] },
        dimensions,
        name,
        DIMENSIONS
      );
    } else {
      rule.scopes.target.args.forEach((arg) => {
        getDimensionsFromRule(
          { key: arg.args[0], value: arg.args[1] },
          dimensions,
          name,
          DIMENSIONS
        );
      });
    }
  }

  if (!rule.key || rule.type === 'rule') return;
  const dimension = rule.key;
  if (!DIMENSIONS[dimension]) return;
  if (!dimensions[dimension]) {
    // eslint-disable-next-line no-param-reassign
    dimensions[dimension] = {};
  }

  const target = rule.value;
  if (!dimensions[dimension][target]) {
    // eslint-disable-next-line no-param-reassign
    dimensions[dimension][target] = {
      value: target,
      instance: 0,
      rule: []
    };
  }
  Object.assign(dimensions[dimension][target], {
    instance: dimensions[dimension][target].instance + 1
  });
  if (dimensions[dimension][target].rule.indexOf(name) === -1)
    dimensions[dimension][target].rule.push(name);
};

const extractPropertiesFromProduct = (product, properties) => {
  const resultProperties = {};
  (product.rules || []).forEach((rule) => {
    const { graph } = rule;
    if (graph) {
      getDimensionsFromRule(graph, resultProperties, rule.name, properties);
    }
  });
  return resultProperties;
};

const extractAllDimensionsFromProduct = (product) => {
  return extractPropertiesFromProduct(
    product,
    product.specification.dimensions
  );
};

const extractAllOutputsFromProduct = (product) => {
  return extractPropertiesFromProduct(product, product.specification.out);
};

module.exports = {
  extractAllDimensionsFromProduct,
  extractAllOutputsFromProduct,
  getDimensionsFromRule
};
