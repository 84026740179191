<template>
  <div v-if="product" :class="containerClass">
    <template v-if="rule && (showGraph || !isTable)">
      <div v-if="!isTestCoverageMode &&
        getFormattedWarnings()[rule.name] &&
        getFormattedWarnings()[rule.name].length > 0
      " class="warnings-table" :class="{ collapsed: hideWarnings }">
        <button class="warning-header" @click="hideWarnings = !hideWarnings">
          <div v-if="hideWarnings" class="el-icon-warning"></div>
          {{ warnings[rule.name].length }}
          Warning{{ warnings[rule.name].length > 1 ? 's' : '' }}
          <div :class="{
            'el-icon-minus': !hideWarnings,
            'el-icon-plus': hideWarnings
          }"></div>
        </button>
        <div class="warnings">
          <button v-for="(warning, index) in warnings[rule.name]" :key="index" class="warning"
            @click="nodeFocus({ node: warning.id, rule: ruleId })">
            <div class="el-icon-warning"></div>
            {{
              $t(`ide.warnings.${warning.code}`, {
                ...warning.data,
                code: warning.data.code
                  ? $t(`ide.warnings.${warning.data.code}`)
                  : undefined,
                inputs: warning.data.inputs
                  ? warning.data.inputs.join(',')
                  : undefined
              })
            }}
          </button>
        </div>
      </div>
      <div v-show="!isLoading" id="ruleEditor" ref="editor" :key="ruleId"></div>
    </template>
    <TableRule v-if="rule && isTable && showTable" :rule="rule" />
    <div v-if="!rule" class="px-4">
      <h3 class="py-3">Not Found</h3>
      <p>This rule does not exist.</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import { isEqual, debounce } from 'lodash';
import { Message } from 'element-ui';
import { RuleEditor } from '../components/AnnotationSupport';
import { getDefaultGraph } from '../util';
import AnnotatedRuleEditor from '../components/AnnotationSupport/AnnotatedRuleEditor';
// eslint-disable-next-line import/no-cycle
import router from '../router';
import * as api from '../api';
import TableRule from '../components/TableRule.vue';
import ProductSaveMixin from '../components/ProductSaveMixin';
import { productTypes } from '../const/product';
import DefinitionListMixin from '../components/DefinitionListMixin';
import PropertiesMixin from '../components/PropertiesMixin';
import { generateHex } from '../helpers';
import { indexingClient } from '../api/searchClient';

let debounceRenderRuleEditorTriggerCounter = 0;

export default {
  name: 'ProductRulesEdit',
  components: { TableRule },
  mixins: [DefinitionListMixin, ProductSaveMixin, PropertiesMixin],
  props: {
    ruleId: {
      required: false,
      type: [String, Number],
      default: undefined
    },
    nodeId: {
      required: false,
      type: [String, Number],
      default: undefined
    }
  },
  data: () => ({
    undoStacks: {},
    unusedRules: [],
    cycleRules: [],
    showSource: false,
    pendingRequest: false,
    defLoaded: false,
    hideWarnings: false,
    definitionsList: {},
    isLoading: false,
    outputMonitoringEnabled: false,
    pendingFocusNode: null,
    debounceRenderRuleEditor: () => {
      debounceRenderRuleEditorTriggerCounter += 1;
    }
  }),
  computed: {
    ...mapState('ruleEditor', ['focusedNode']),
    ...mapState('product', [
      'inspectorIsVisible',
      'ruleDisplayType',
      'warnings',
      'graphWithId',
      'requestWarnings'
    ]),
    ...mapState('productTests', { tests: 'tests', annotatedGraph: 'graph' }),
    ...mapGetters('productProperty', ['endorsements']),
    ...mapGetters('product', ['isReadOnly', 'isTestCoverageMode']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('definitions', ['definitions']),
    ...mapState('auth', ['tenant']),
    productRules() {
      return this.product.rules;
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    rule() {
      return this.productRules.find((r) => r.id === Number(this.ruleId));
    },
    graph() {
      if (!this.rule) {
        return null;
      }
      return this.rule.graph || getDefaultGraph(this.rule.name);
    },
    containerClass() {
      return `rule-editor-container ${this.isTable ? 'table-container' : 'graph-container'
        }`;
    },
    isTable() {
      if (!this.rule) return false;
      return this.rule.meta_data && this.rule.meta_data.source === 'XLSX';
    },
    showGraph() {
      return this.rule && this.ruleDisplayType === 'graph';
    },
    showTable() {
      return this.rule && this.ruleDisplayType === 'table';
    }
  },
  watch: {
    graph(oldValue, newValue) {
      if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
        return;
      }
      Vue.nextTick(() => {
        this.outputMonitoring('watch:graph');
        this.debounceRenderRuleEditor();
      });
    },
    focusedNode(value) {
      this.pendingFocusNode = value;
      this.requestNodeFocus(this.ruleId);
    },
    rule: {
      immediate: true,
      deep: true,
      handler(newRule, oldRule) {
        const hasRules = oldRule && newRule;
        if (oldRule && this.editor) {
          this.undoStacks[oldRule.id] = this.editor.graph.undoStack || null;
        }
        const differentRule = hasRules && oldRule.id !== newRule.id;
        let nameChanged = hasRules && oldRule.name !== newRule.name;
        if (oldRule && newRule) {
          nameChanged = nameChanged || differentRule;
        }
        const lockedStatusChanged =
          hasRules && oldRule.is_locked !== newRule.is_locked;
        if (!newRule || differentRule || nameChanged || lockedStatusChanged) {
          Vue.nextTick(() => {
            this.outputMonitoring('watch:rule');
            this.debounceRenderRuleEditor();
          });
        }
      }
    },
    inspectorIsVisible: {
      handler() {
        if (this.editor) {
          if (this.inspectorIsVisible) {
            this.editor.showInspector();
          } else {
            this.editor.hideInspector();
          }
        }
      }
    },
    '$i18n.locale': {
      handler() {
        this.outputMonitoring('watch:$i18n.locale');
        this.debounceRenderRuleEditor();
      }
    },
    tests: {
      // Received new test results
      handler() {
        if (this.isTestCoverageMode) {
          this.outputMonitoring('watch:tests');
          this.debounceRenderRuleEditor();
        }
      }
    },
    warnings: {
      // Received new test results
      handler() {
        if (!this.isTestCoverageMode) {
          this.outputMonitoring('watch:warnings');
          this.debounceRenderRuleEditor();
        }
      }
    },
    isTestCoverageMode: {
      handler() {
        this.outputMonitoring('watch:isTestCoverageMode');
        this.debounceRenderRuleEditor();
      }
    },
    endorsements() {
      this.outputMonitoring('watch:endorsements');
      this.debounceRenderRuleEditor();
    },
  },

  async mounted() {
    await this.fetchProductProperties(this.product.id);
    this.$emit('update');
    this.outputMonitoring('define debounceRenderRuleEditor');
    this.debounceRenderRuleEditor = debounce(this.renderRuleEditor, 200);
    if (debounceRenderRuleEditorTriggerCounter > 0) {
      this.debounceRenderRuleEditor();
      debounceRenderRuleEditorTriggerCounter = 0;
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },
  methods: {
    ...mapActions('ruleEditor', ['nodeFocus']),
    ...mapActions('uiProductSearch', ['triggerSearch']),
    ...mapActions('product', [
      'setTestCoverageMode',
      'setProductConfigActiveTab',
      'fetchProduct',
      'fetchTests',
      'fetchProductWarnings',
      'showInspector',
      'refreshProduct',
      'updateRule'
    ]),
    ...mapActions('definitions', [
      'fetchDefinitions',
      'fetchItemsForDefinition'
    ]),
    ...mapActions('productProperty', [
      'updateProductProperty',
      'createProductProperty',
      'fetchProductProperties',
      'fetchDimensions'
    ]),
    requestNodeFocus(ruleId) {
      requestAnimationFrame(() => {
        if (
          this.pendingFocusNode &&
          this.editor &&
          Number(ruleId) === Number(this.pendingFocusNode.rule)
        ) {
          this.editor.focusNode(this.pendingFocusNode.node);
          this.pendingFocusNode = null;
        }
      });
    },
    outputMonitoring(message) {
      if (!this.outputMonitoringEnabled) return;
      // eslint-disable-next-line no-console
      console.log(message);
    },
    getFormattedWarnings() {
      return this.isTestCoverageMode
        ? {}
        : Object.keys(this.warnings || []).reduce((warnings, rule) => {
          // eslint-disable-next-line no-param-reassign
          warnings[rule] = this.warnings[rule]
            .filter((warning) => warning.id !== 0)
            .map((warning) => {
              return {
                ...warning,
                description: this.$t(`ide.warnings.${warning.code}`, {
                  ...warning.data,
                  code: warning.data.code
                    ? this.$t(`ide.warnings.${warning.data.code}`)
                    : undefined,
                  inputs: warning.data.inputs
                    ? warning.data.inputs.join(',')
                    : undefined
                }),
                solutions: warning.solutions.map((solution) => {
                  let onclick = null;
                  // eslint-disable-next-line no-param-reassign
                  solution.description = this.$t(
                    `ide.solutions.${solution.code}`,
                    {
                      ...solution.data,
                      code: solution.data.code
                        ? this.$t(`ide.solutions.${solution.data.code}`)
                        : undefined,
                      inputs: solution.data.inputs
                        ? solution.data.inputs.join(',')
                        : undefined
                    }
                  );
                  switch (solution.code) {
                    case 'KeepMagicArrow':
                    case 'AddMagicArrow':
                    case 'RemoveMagicArrow':
                    case 'ReplaceNode':
                      onclick = function () {
                        this.save(
                          this.replaceNode(
                            this.rule.graph,
                            warning.id,
                            solution.graph
                          )
                        );
                        // eslint-disable-next-line func-names
                      }.bind(this);
                      break;
                    case 'DeleteNode':
                      onclick = function () {
                        this.save(
                          this.deleteNode(this.rule.graph, warning.id)
                        );
                        // eslint-disable-next-line func-names
                      }.bind(this);
                      break;
                    case 'CreateRule':
                      onclick = function () {
                        this.addRule(solution.data.rule.trim());
                        this.outputMonitoring(
                          'getFormattedWarnings:CreateRule'
                        );
                        this.debounceRenderRuleEditor();
                        // eslint-disable-next-line func-names
                      }.bind(this);
                      break;
                    case 'CreateInput':
                      onclick = async function () {
                        for (
                          let i = 0;
                          i < solution.data.inputs.length;
                          i += 1
                        ) {
                          const input = this.toValidName(
                            solution.data.inputs[i]
                          );
                          const propertyData = {
                            name: input,
                            type:
                              solution.data.type === 'dimension'
                                ? 'enum'
                                : 'number',
                            color: generateHex(input),
                            __type: solution.data.type.toUpperCase()
                          };
                          const specType =
                            solution.data.type === 'dimension'
                              ? 'dimensions'
                              : 'in';
                          if (
                            !!this.product.specification[specType] ||
                            !this.product.specification[specType][input]
                          ) {
                            // eslint-disable-next-line no-await-in-loop
                            await this.createProductProperty({
                              productId: this.product.id,
                              propertyData
                            });
                            this.outputMonitoring(
                              'getFormattedWarnings:CreateInput'
                            );
                            this.debounceRenderRuleEditor();
                          }
                        }
                        // eslint-disable-next-line func-names
                      }.bind(this);
                      break;
                    default:
                      break;
                  }
                  return {
                    ...solution,
                    onclick
                  };
                })
              };
            });
          return warnings;
        }, {});
    },
    async setTestCoverage(setCover) {
      this.setTestCoverageMode({ set: setCover });
    },
    clearUndoStack(graph) {
      if ('undoStack' in graph) {
        // eslint-disable-next-line no-param-reassign
        delete graph.undoStack;
      }
      if (graph.terms) {
        graph.terms.forEach((child) => this.clearUndoStack(child));
      }
    },
    deleteNode(node, id) {
      if (node.terms) {
        // eslint-disable-next-line no-param-reassign
        node.terms = node.terms
          .filter((child) => child.id !== id)
          .map((child) => this.deleteNode(child, id));
      }
      return node;
    },
    replaceNode(node, id, newNode) {
      if (node.terms) {
        // eslint-disable-next-line no-param-reassign
        node.terms = node.terms.map((child) =>
          child.id === id ? newNode : this.replaceNode(child, id, newNode)
        );
      }
      return node;
    },
    isDimension() {
      return false;
    },
    async save(graph, refresh = true) {
      const clearGraph = { ...graph };
      this.clearUndoStack(clearGraph);
      this.rule.graph = clearGraph;
      this.$emit('update');
      await this.updateRule({
        ruleId: this.ruleId,
        rule: this.rule
      });
      const productId = this.product.id;
      if (refresh) {
        await this.fetchProduct({
          productId,
          silent: true
        });
      } else {
        await Vue.nextTick();
        await this.fetchTests();

        if (this.requestWarnings) {
          await this.fetchProductWarnings({ tenant: this.tenant });
        }
      }
      this.$emit('change-made');
      await indexingClient(this.tenant, this.product.id);
      this.triggerSearch();
    },
    async loadDefinitionByProperty(property) {
      const { definitionListId, type } =
        this.product.specification.dimensions[property] ||
        this.product.specification.out[property];
      const definitions = await this.suggestionsByProperty(
        type,
        property,
        definitionListId,
        this.product.id
      );
      return { property, definitions };
    },
    async loadAllDefinitions() {
      this.isLoading = true;
      const allProperties = Object.keys(this.product.specification.out)
        .concat(Object.keys(this.product.specification.dimensions))
        .filter(
          (p) =>
            (this.product.specification.out[p] &&
              !!this.product.specification.out[p].definitionListId) ||
            this.product.specification.dimensions[p]
        );
      const promises = [];
      await this.fetchDimensions(this.product.id);
      allProperties.forEach((p) => {
        promises.push(this.loadDefinitionByProperty(p));
      });
      const result = await Promise.all(promises);
      result.forEach((r) => {
        this.definitionsList[r.property] = r.definitions.map((d) => ({
          ...d,
          label: typeof d.label !== 'string' ? d.label.toString() : d.label
        }));
      });
      this.isLoading = false;
      return result;
    },
    async renderRuleEditor() {
      if (!this.graph) {
        return;
      }
      this.outputMonitoring('renderRuleEditor');
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }

      if (!Object.keys(this.definitionsList).length) {
        await this.loadAllDefinitions();
      }
      await Vue.nextTick();
      let defaultRule = this.product.rules.find(
        (r) => r.id === this.product.main_rule_id
      );
      if (!defaultRule) {
        defaultRule = this.product.rules.find(
          (r) => r.name === 'Cover' || r.name === 'Questions'
        );
      }
      this.graph.undoStack = this.undoStacks[this.ruleId] || null;

      if (!this.$refs.editor) return;

      const ruleName = this.rule ? this.rule.name : defaultRule.name;
      const config = {
        container: this.$refs.editor,
        data: this.graph,
        ruleName,
        product: this.product,
        definitions: this.definitionsList,
        translate: this.$t.bind(this),
        showSource: this.showSource,
        nodeId: this.nodeId,
        endorsements: this.product.specification.endorsements
          ? Object.values(this.product.specification.endorsements)
          : this.endorsements,
        warnings: this.getFormattedWarnings(),
        lang: {
          selected: window.localStorage.locale || 'en',
          prefered: this.product.defaultLanguage || 'en'
        },
        mode: [
          productTypes.QUESTIONNAIRE,
          productTypes.SHADOW_QUESTIONNAIRE
        ].includes(this.product.type)
          ? 'questionnaire'
          : 'default',
        readOnly:
          this.isReadOnlyMode ||
          this.isTestCoverageMode ||
          (this.rule && this.rule.is_locked) ||
          this.product.locked,
        isGuestUser: this.isGuest(this.product.team.slug),
        showInspector: this.inspectorIsVisible,
        additionalBars: this.additionalBars
      };

      if (
        this.isTestCoverageMode &&
        this.annotatedGraph &&
        this.annotatedGraph[ruleName]
      ) {
        const mainArrayNode = this.annotatedGraph[ruleName];
        // Recreate the structure of the rule graph
        config.data = {
          type: 'operator',
          operator: 'and',
          terms: [
            {
              type: 'rule',
              key: ruleName,
              isRepresentation: true,
              sources: {}
            },
            ...mainArrayNode.terms,
            {
              type: 'rule',
              key: 'ROOT-OUT',
              isRepresentation: true,
              sources: {}
            }
          ],
          metadata: {},
          options: {},
          annotations: mainArrayNode.annotations,
          additionalBars: this.additionalBars,
          version: '2021-05-04'
        };
        this.editor = new AnnotatedRuleEditor(config);
      } else {
        this.editor = new RuleEditor(config);
        this.editor.on('onGraphRendered', () => {
          this.requestNodeFocus(this.ruleId);
        });
        this.editor.render();
      }

      this.editor.on('onUpdate', (graph) => {
        this.save(graph, false);
      });
      this.editor.on('showInspector', (show) => {
        this.showInspector({ show });
      });
      this.editor.on('addRule', (name, graph) => {
        this.addRule(name, graph);
      });
      this.editor.on('editRule', (id) => {
        this.navigateToRule(id);
      });
      this.editor.on('editDimension', (id) => {
        this.navigateToDimension(id);
      });
      this.editor.on('editInputs', () => {
        this.navigateToInputs();
      });
      this.editor.on('editTermDimension', async (term, oldTerm) => {
        this.checkAddedTerm(term);
        this.checkDefaultDimensionTerm(oldTerm, term);
      });
      this.editor.on('editTermUnauthorized', async (value) => {
        Message.error(
          this.$t('rule-editor.node_reserved_word', { name: value })
        );
      });

      this.editor.on('deleteDimensionNode', (term) => {
        this.checkDefaultDimensionTerm(term);
      });
      const nodeId = this.$route.hash.trim().replace('#', '');
      if (!Number.isNaN(Number(nodeId))) {
        this.hashBasedFocusNode = nodeId;
        this.editor.focusNode(nodeId);
      }
      if (this.additionalBars) {
        if (this.isTestCoverageMode) {
          this.additionalBars[0].items[0].setSelected(false);
          this.additionalBars[0].items[1].setSelected(true);
        } else {
          this.additionalBars[0].items[0].setSelected(true);
          this.additionalBars[0].items[1].setSelected(false);
        }

        if (!Object.keys(this.definitionsList).length) {
          await this.loadAllDefinitions();
        }
        await Vue.nextTick();
        let defaultRule = this.product.rules.find(
          (r) => r.id === this.product.main_rule_id
        );
        if (!defaultRule) {
          defaultRule = this.product.rules.find(
            (r) => r.name === 'Cover' || r.name === 'Questions'
          );
        }
        this.graph.undoStack = this.undoStacks[this.ruleId] || null;

        if (!this.$refs.editor) return;

        const ruleName = this.rule ? this.rule.name : defaultRule.name;
        const config = {
          container: this.$refs.editor,
          data: this.graph,
          ruleName,
          product: this.product,
          definitions: this.definitionsList,
          translate: this.$t.bind(this),
          showSource: this.showSource,
          nodeId: this.nodeId,
          endorsements: this.product.specification.endorsements
            ? Object.values(this.product.specification.endorsements)
            : this.endorsements,
          warnings: this.getFormattedWarnings(),
          lang: {
            selected: window.localStorage.locale || 'en',
            prefered: this.product.defaultLanguage || 'en'
          },
          mode: [
            productTypes.QUESTIONNAIRE,
            productTypes.SHADOW_QUESTIONNAIRE
          ].includes(this.product.type)
            ? 'questionnaire'
            : 'default',
          readOnly:
            this.isReadOnlyMode ||
            this.isTestCoverageMode ||
            (this.rule && this.rule.is_locked) ||
            this.product.locked,
          isGuestUser: this.isGuest(this.product.team.slug),
          showInspector: this.inspectorIsVisible,
          additionalBars: this.additionalBars
        };

        if (
          this.isTestCoverageMode &&
          this.annotatedGraph &&
          this.annotatedGraph[ruleName]
        ) {
          const mainArrayNode = this.annotatedGraph[ruleName];
          // Recreate the structure of the rule graph
          config.data = {
            type: 'operator',
            operator: 'and',
            terms: [
              {
                type: 'rule',
                key: ruleName,
                isRepresentation: true,
                sources: {}
              },
              ...mainArrayNode.terms,
              {
                type: 'rule',
                key: 'ROOT-OUT',
                isRepresentation: true,
                sources: {}
              }
            ],
            metadata: {},
            options: {},
            annotations: mainArrayNode.annotations,
            additionalBars: this.additionalBars,
            version: '2021-05-04'
          };
          this.editor = new AnnotatedRuleEditor(config);
        } else {
          this.editor = new RuleEditor(config);
          this.editor.on('onGraphRendered', () => {
            this.requestNodeFocus(this.ruleId);
          });
          this.editor.render();
        }

        this.editor.on('onUpdate', (graph) => {
          this.save(graph, false);
        });
        this.editor.on('showInspector', (show) => {
          this.showInspector({ show });
        });
        this.editor.on('addRule', (name, graph) => {
          this.addRule(name, graph);
        });
        this.editor.on('editRule', (id) => {
          this.navigateToRule(id);
        });
        this.editor.on('editDimension', (id) => {
          this.navigateToDimension(id);
        });
        this.editor.on('editInputs', () => {
          this.navigateToInputs();
        });
        this.editor.on('editTermDimension', async (term, oldTerm) => {
          this.checkAddedTerm(term);
          this.checkDefaultDimensionTerm(oldTerm, term);
        });
        this.editor.on('editTermUnauthorized', async (value) => {
          Message.error(
            this.$t('rule-editor.node_reserved_word', { name: value })
          );
        });

        this.editor.on('deleteDimensionNode', (term) => {
          this.checkDefaultDimensionTerm(term);
        });
        if (this.additionalBars) {
          if (this.isTestCoverageMode) {
            this.additionalBars[0].items[0].setSelected(false);
            this.additionalBars[0].items[1].setSelected(true);
          } else {
            this.additionalBars[0].items[0].setSelected(true);
            this.additionalBars[0].items[1].setSelected(false);
          }
        }
      }
    },
    navigateToRule(ruleId) {
      const { currentRoute } = router;
      const params = { ...currentRoute.params, ruleId };
      router.push({
        name: 'product-rules-edit',
        params,
        query: currentRoute.query
      });
    },
    navigateToDimension(dimension) {
      const { currentRoute } = router;
      const params = { ...currentRoute.params, dimension };
      router.push({
        name: 'product-terms-index',
        params,
        query: currentRoute.query
      });
    },
    navigateToInputs() {
      const { currentRoute } = router;
      const params = { ...currentRoute.params };
      router.push(
        {
          name: 'product-configuration',
          params,
          query: currentRoute.query
        },
        () => {
          this.setProductConfigActiveTab({ activeTab: 'input' });
        }
      );
    },
    async addRule(name, extractedNodes = null) {
      const productId = this.product.id;
      const { data } = await api.createRule(productId, name);
      // Called when using the 'Extract to new rule' or 'Duplicate rule' functions
      if (extractedNodes) {
        const graph = getDefaultGraph(name, extractedNodes);
        await this.updateRule({
          ruleId: data.id,
          rule: {
            name,
            productId,
            graph,
            is_locked: data.is_locked || false,
            id: data.id
          }
        });
      }
      await this.refreshProduct({
        productId
      });

      Vue.nextTick(() => {
        this.navigateToRule(data.id);
      });
      this.$emit('update');
    },
    async checkAddedTerm(term) {
      let foundDefinition;
      let foundTerm;
      let { dimension: dimName } = term;
      if (dimName === 'OUT') {
        dimName = term.dimension_name;
        if (!this.definitionsList[dimName]) return;
        foundTerm = this.definitionsList[dimName].find(
          (termElement) =>
            term.terms.includes(termElement.key) ||
            term.terms.includes(termElement.label)
        );
      } else {
        if (!this.definitionsList[dimName]) return;
        if (typeof term.value !== 'string') {
          term.value = term.value.toString();
        }
        foundTerm = this.definitionsList[dimName].find(
          (termElement) =>
            termElement.key === term.value || termElement.label === term.value
        );
      }
      if (!foundTerm) return;

      if (this.product.terms[dimName]) {
        // The new element can either be a product term, or a definition inside a term.
        foundDefinition = this.product.terms[dimName].find((productTerm) => {
          return (
            productTerm.name === foundTerm.label ||
            productTerm.definitions.find(
              (def) =>
                def.displayName === (foundTerm.displayName || foundTerm.label)
            )
          );
        });
      }

      // if definition is not present in any term, we create a new term
      if (
        !foundDefinition &&
        foundTerm.metadata &&
        foundTerm.metadata.definitionId
      ) {
        await api.createTerm(
          this.product.id,
          {
            definitions: [{ _id: foundTerm.metadata.definitionId }],
            dimension: dimName,
            name: foundTerm.label
          },
          this.tenant
        );
        await this.fetchProduct({
          productId: this.product.id,
          tenant: this.tenant
        });
      }
    },
    async checkDefaultDimensionTerm(oldTerm, newTerm = undefined) {
      try {
        if (!newTerm || oldTerm === newTerm.value) {
          return;
        }
        const { dimension: termDimension } = newTerm;
        const dimension = this.product.specification.dimensions[termDimension];
        if (!dimension) return;

        const isUnmatchedTerm =
          !this.product.terms[termDimension] ||
          !this.product.terms[termDimension].find(
            (term) => term.name === oldTerm
          );

        const { defaultValue } = dimension.metadata;
        if (isUnmatchedTerm && defaultValue && defaultValue === oldTerm) {
          delete dimension.metadata.defaultValue;
          await this.updateProductProperty({
            productId: this.product.id,
            propertyName: termDimension,
            propertyData: dimension
          });
        }
      } catch (e) {
        throw new Error('failed to check added term', e);
      }
    }
  }
};
</script>

<style lang="scss">
@import '@axa-getd/lib-rule-editor/css/rule-editor.scss';

.rule-editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .options {
    height: 50px;

    .el-switch {
      margin-left: 2em;
    }
  }

  #ruleEditor {
    position: relative;
    flex: 1;
  }
}

.graph-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.table-container {
  overflow: auto;
}

.warnings-table {
  position: fixed;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 14px;
  background: #fff;
  z-index: 100;
  border: solid 1px #8888bf;
  border-radius: 4px;
  height: auto;
  width: 400px;
  max-height: 230px;
  overflow: hidden;
  background: #fff;
  transition: width 500ms, max-height 500ms;

  &.collapsed {
    max-height: 30px;
    width: 200px;
    background: orange;

    .warning-header {
      border-radius: 4px;
    }
  }

  .warnings {
    overflow: auto;
    max-height: 200px;
  }

  .warning-header {
    color: #000;
    background: transparent;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    position: relative;
    border: none;
    border-bottom: solid 1px #8888bf;

    .el-icon-warning {
      position: absolute;
      left: 5px;
      top: 5px;
      height: 20px;
      width: 20px;
    }

    .el-icon-plus,
    .el-icon-minus {
      position: absolute;
      right: 5px;
      top: 5px;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      font-size: 14px;
      padding: 0;
      line-height: 20px;
      text-align: center;
    }
  }

  .warning {
    display: block;
    color: #000;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    background: #fff;
    text-transform: initial;
    width: 100%;
    z-index: 10;
    padding: 0 10px;
    text-align: left;
    cursor: pointer;
    border: none;
    border-top: solid 1px #8888bf;

    .el-icon-warning {
      color: #ff7979;
      margin-right: 5px;
    }

    &:hover {
      background: #8888bf;
    }

    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }
  }

  max-width: 400px;
}
</style>
