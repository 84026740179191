/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as api from '../../../api';
import { apiMutations, queryApi, getTenant } from '../../helper';
import { productTypes } from '../../../const/product';

const getInitialState = () => ({
  isLoading: true,
  tests: [],
  stats: {
    total: 0,
    success: 0,
    error: null
  },
  graph: [],
  errors: []
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    reset: ({ commit }) => commit('RESET'),
    fetch: async ({ commit, rootState }) => {
      const { product } = rootState.product;
      const { id } = product;
      if (!id) throw Error('Unable to fetch tests, unknown product');

      // prevent requesting tests for questionnaires and its releases
      if (
        [
          productTypes.QUESTIONNAIRE,
          productTypes.SHADOW_QUESTIONNAIRE
        ].includes(product.type)
      )
        return;

      const tenant = getTenant(rootState);

      await queryApi(commit, 'FETCH_TESTS', async () => {
        const { data } = await api.getTests(id, null, true, false, tenant);

        // for now until the new compute method is integrated everywhere
        // if we have a result, we can set the error to null
        data.stats.error = null;
        const { tests, stats, annotatedGraph: graph } = data;
        return { tests, stats, graph };
      });
    }
  },
  getters: {
    getTest: (state) => (id) => {
      const test = state.tests && state.tests.find((t) => t.id === id);
      return test || {};
    },
    hasError: (state) => state.errors.length > 0
  },
  mutations: {
    SET_LOADING: (state, val) => {
      state.isLoading = val;
    },
    RESET: (state) => Object.assign(state, getInitialState()),
    ...apiMutations(
      'FETCH_TESTS',
      (state) => {
        state.isLoading = true;
      },
      (state, { tests, stats, graph }) => {
        state.tests = Object.freeze(tests);
        state.stats = Object.freeze(stats);
        state.graph = Object.freeze(graph);

        state.isLoading = false;
      },
      (state, errors) => {
        state.errors = Object.freeze(errors);
        state.isLoading = false;
      }
    )
  }
};
