<template>
  <footer v-selector class="app-footer py-1 px-2">
    <small>
      Version {{ renderVersion }} -
      {{ prettyDate }}
    </small>
  </footer>
</template>

<script>
import * as dayjs from 'dayjs';

export default {
  name: 'VersionFooter',
  data: () => ({ env: process.env }),
  computed: {
    prettyDate() {
      return dayjs(this.env.VUE_APP_BUILDDATE)
        .locale(window.localStorage.locale || 'en')
        .format('YYYY-MM-DD HH:mm:ss');
    },
    renderVersion() {
      if (this.env.VUE_APP_BRANCH === 'main') {
        return `${this.env.VUE_APP_VERSION} (${this.env.VUE_APP_HASH})`;
      }
      return `${this.env.VUE_APP_BRANCH} (${this.env.VUE_APP_HASH})`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@axatechlab/assets/scss/_variables';

.app-footer {
  margin-top: 100px;
  width: 100%;
  padding: 50px 100px;
  background-color: $color-gray--lighter;
  border-top: solid 1px $color-gray--light;
  font-size: 16px;
  line-height: 20px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #f7f5c6;
}

.help {
  display: block;
  float: right;
}
</style>
