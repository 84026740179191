<template>
    <UiIconBadge :text="text" :icon="icon" :theme="theme" :hide="false"></UiIconBadge>
</template>

<script>
export default {
    name: 'PublicationStatusTag',
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        theme() {
            switch (this.status) {
                case 'PRODUCTION':
                    return 'success';
                case 'PRODUCTION-ERROR':
                    return 'danger';
                case 'PRODUCTION-PENDING':
                    return 'gray';
                case 'DRAFT':
                    return 'apache';
                case 'EXPIRED':
                    return 'gray';
                default:
                    return 'light';
            }
        },
        icon() {
            switch (this.status) {
                case 'PRODUCTION':
                    return 'check-circle-fill';
                case 'PRODUCTION-ERROR':
                    return 'x-circle-fill';
                case 'PRODUCTION-PENDING':
                    return 'clock-history';
                case 'DRAFT':
                    return 'pencil-fill';
                case 'EXPIRED':
                    return 'hourglass-bottom';
                default:
                    return 'clock-history';
            }
        },
        text() {
            if (this.status.indexOf('PRODUCTION') !== -1) {
                return 'PRODUCTION';
            }
            return this.status;
        }
    }
};
</script>

<style scoped>
root {
    cursor: pointer;
}

.mr-1 {
    margin-right: 0.25rem;
}
</style>