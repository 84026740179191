import { render, staticRenderFns } from "./ProductTestsEditor.vue?vue&type=template&id=38b1daa8&scoped=true"
import script from "./ProductTestsEditor.vue?vue&type=script&lang=js"
export * from "./ProductTestsEditor.vue?vue&type=script&lang=js"
import style0 from "./ProductTestsEditor.vue?vue&type=style&index=0&id=38b1daa8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b1daa8",
  null
  
)

export default component.exports