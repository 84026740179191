<template>
  <div class="test flex-1 d-flex flex-column overflow-hidden">

    <UiLoadingArea :is-loading="isLoading">
      <div class="test-wrapper d-flex flex-column flex-grow-1">
        <UiToolbar class="toolbar align-items-start p-2">
          <template #side>
            <div class="toolbar-buttons">
              <b-button variant="primary" class="testActionButton" data-cy="submit-test" @click="createUpdateTest">
                {{ $t('tests.v2.submit') }}
              </b-button>
              <b-dropdown variant="outline-primary" no-caret right>
                <template #button-content>
                  <b-icon-three-dots />
                </template>
                <b-dropdown-item @click="handleGeneralAction('duplicateTest')">
                  <i class="fas fa-copy"></i>
                  {{ $t('tests.v2.duplicate_test') }}
                </b-dropdown-item>
                <b-dropdown-item @click="handleGeneralAction('deleteTest')">
                  <i class="fas fa-trash"></i>
                  {{ $t('tests.v2.delete_test') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <div class="d-flex flex-column align-items-start w-100 ml-2">
            <back-button data-cy="back-button" class="toolbar-back" :text="$t('tests.back_to_tests')"></back-button>

            <div class="statusAndName w-100">
              <div class="status" :class="{
                success: lastSubmittedTestStatus === true,
                failed: lastSubmittedTestStatus === false
              }" data-cy="status-test-name">
                <div class="statusAndName-name">
                  <b-form-input v-if="editingName" ref="nameInput" v-model="testName" :maxlength="256"
                    :placeholder="$t('tests.add_test_name')" data-cy="test-name" @input="checkValidityName"
                    @blur="toggleEditingName" />
                  <span v-else data-cy="test-name-action" @click="toggleEditingName">
                    <span :class="{ 'placeholder-text': !testName && nameIsDirty }">
                      {{ testName || $t('tests.add_test_name') }}
                    </span>
                    <span v-if="!isReadOnlyMode" class="statusAndName-pencil">
                      <UiPencilIcon />
                    </span>
                  </span>
                  <div v-if="errorNoName && nameIsDirty" class="error-no-data">Test name can't be empty</div>
                </div>
                <div class="statusAndName-description">
                  <b-form-input v-if="editingDescription" ref="descriptionInput" v-model="testDescription"
                    :maxlength="500" :placeholder="$t('tests.test_description')" data-cy="test-description"
                    @input="checkValidityDescription" @blur="toggleEditingDescription" />
                  <span v-else data-cy="test-description-action" @click="toggleEditingDescription">
                    <span :class="{ 'placeholder-text': !testDescription && descriptionIsDirty }">{{ testDescription ||
                      $t('tests.test_description') }}</span>
                    <span v-if="!isReadOnlyMode" class="statusAndName-pencil">
                      <UiPencilIcon />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </UiToolbar>
        <div class="test-container flex-grow-1 flex-shrink-1 d-flex overflow-hidden" data-cy="test-container">
          <div id="settings" data-cy="test-settings" class="d-flex flex-column overflow-auto px-2">
            <h2>{{ $t('tests.test_case') }}</h2>
            <div v-if="isTestgen">
              <span style="color: #00008f;" class="mt-3 mb-0"><b-icon-stars /> {{ $t('tests.v2.label') }}</span>
              <b-icon icon="exclamation-circle" class="exclamation-circle-as-a-i" title="Show more details"
                @click="displayPromptDetailsModal" />
              <div class="textarea" v-html="processPrompt">
              </div>
            </div>

            <h3 class="sectionTitle">{{ $t('tests.v2.assertions') }}</h3>
            <div>
              <span v-if="errorNoAssertion" class="error-no-data">{{ $t("tests.test_assertion_mandatory") }}</span>
              <questions-form id="assertionsForm" :key="formsKey.assertions" :readonly="isReadOnlyMode"
                :questions="formatedAssertions" data-cy="assertions-form" @answerQuestion="setAssertionExpectedVal"
                @clearQuestion="unsetAssertion">
              </questions-form>
            </div>

            <h3 v-if="formatedEndorsements.length > 0" class="sectionTitle">{{ $t('tests.v2.endorsements') }}</h3>
            <div>
              <questions-form v-if="formatedEndorsements.length > 0" id="endorsementsForm" :key="formsKey.endorsements"
                ref="endorsements" :readonly="isReadOnlyMode" :questions="formatedEndorsements"
                data-cy="endorsements-form" @inputShow="endorsementsChange">
              </questions-form>
            </div>

            <h3 class="sectionTitle">{{ $t('tests.v2.setinputs') }}</h3>

            <b-row class="mb-3">
              <b-col cols="12">
                <b-form-input v-model="searchInput" placeholder="Search input"></b-form-input>
              </b-col>
            </b-row>

            <div>
              <questions-form id="questionsForm" :key="formsKey.questions" :readonly="isReadOnlyMode"
                :displayed="displayedInputs" :questions="formatedQuestions" data-cy="inputs-form"
                @answerQuestion="answerQuestion" @clearQuestion="clearQuestion">
              </questions-form>
            </div>

            <h3 class="sectionTitle">{{ $t('tests.v2.fillthedimensionsvalues') }}</h3>

            <b-row class="mb-3">
              <b-col cols="12">
                <b-form-input v-model="searchDimension" placeholder="Search dimension"></b-form-input>
              </b-col>
            </b-row>

            <div>
              <questions-form id="questionsForm" :key="formsKey.questions" :readonly="isReadOnlyMode"
                :displayed="displayedDimensions" :questions="formatedQuestions" data-cy="inputs-form"
                @answerQuestion="answerQuestion" @clearQuestion="clearQuestion">
              </questions-form>
            </div>
          </div>

          <div id="results" class="d-flex flex-column flex-grow-1 flex-shrink-1 overflow-hidden">
            <div v-if="!testIsNew && resultsSubmitted" id="outputs">
              <h2>{{ $t('tests.test_result') }}</h2>
              <div class="status" :class="{
                success: lastSubmittedTestStatus === true,
                failed: lastSubmittedTestStatus === false
              }" data-cy="test-status" v-if="lastSubmittedTestStatus !== null && !testIsNew">
                Test {{ lastSubmittedTestStatus ? $t('tests.success') : $t('tests.failed') }}
              </div>
              <div class="assertions">

                <UiTable v-if="!viewAsJSON" :data="formatedResultsData" variant="padded" class="innerTable"
                  data-cy="results-table">
                  <el-table-column prop="status" :label="$t('tests.output.status')">
                    <template slot-scope="scope">
                      <i v-if="scope.row.status" class="singleTestResult el-icon-circle-check"></i>
                      <i v-else class="singleTestResult el-icon-circle-close"></i>
                    </template>
                  </el-table-column>
                  <el-table-column prop="description" :label="$t('tests.output.type')"></el-table-column>
                  <el-table-column prop="expected" :label="$t('tests.output.expected')"></el-table-column>
                  <el-table-column prop="actual" :label="$t('tests.output.output')"></el-table-column>
                </UiTable>

                <textarea v-if="viewAsJSON" v-model="response" class="response response--dark response--large" readonly
                  data-cy="results-json" />
              </div>
            </div>
            <div v-else-if="!resultsSubmitted" class="please-submit-message flex-grow-1">
              <div>
                <b-icon icon="exclamation-circle" scale="3"></b-icon>
                <p>{{ $t('tests.submit_to_view') }}</p>
              </div>
            </div>
            <div id="graph" class="flex-grow-1 flex-shrink-1 overflow-hidden" :class="{
              'graph-fullwidth': graphFullscreen
            }" data-cy="graph-container" v-if="resultsSubmitted">
              <div class="graph">
                <b-button v-if="graphAvailable" class="graph-fullscreen-toggle" variant=""
                  data-cy="graph-fullscreen-toggle" @click="graphFullscreenToggle">
                  <b-icon v-if="!graphFullscreen" icon="arrows-fullscreen" scale="1"></b-icon>
                  <b-icon v-if="graphFullscreen" icon="arrows-angle-contract" scale="1"></b-icon>
                  &nbsp;
                  {{ graphFullscreen ? $t('tests.v2.exit_fullscreen') : $t('tests.v2.fullscreen') }}
                </b-button>
                <div v-show="testIsNew" class="graph-error">
                  <div>
                    <b-icon icon="arrow-repeat" scale="10"></b-icon>
                  </div>
                  <div>
                    {{ $t('tests.submit_to_view') }}
                  </div>
                </div>
                <div ref="editor" class="w-100 h-100">
                  <div style="margin: auto;text-align: center;height: inherit;writing-mode: vertical-rl;">
                    <b-spinner type="grow" variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <prompt-details-modal v-if="displayingPromptDetailsModal" name="PromptDetailsModal"
          :prompt="testToUse.definition.testgen" @close="hidePromptDetailsModal" />

      </div>
    </UiLoadingArea>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Message, MessageBox } from 'element-ui';
import { RuleEditor } from '../components/AnnotationSupport';
// eslint-disable-next-line import/no-cycle
import * as api from '../api';
import { extractDefaultValues } from '../util';
import QuestionsForm from '../components/ProductTests/QuestionsForm.vue';
import { displayName } from '../helpers';
import PromptDetailsModal from './PromptDetailsModal.vue';
import BackButton from '@/components/BackButton.vue';


export default {
  name: 'ProductTestsEditor',
  components: {
    QuestionsForm,
    PromptDetailsModal,
    BackButton
  },
  beforeRouteLeave: function (to, from, next) {
    this.handleUnsavedChanges(next);
  },
  data() {
    return {
      isLoading: true,
      dimensions: null,
      testId: undefined,
      testName: undefined,
      testDescription: undefined,
      editingName: false,
      editingDescription: false,
      selected: {},
      response: {},
      isNewTest: false,
      isCopyTest: false,
      viewAsJSON: false,
      inputIds: {},
      dimensionIds: {},
      errorNoName: false,
      nameIsDirty: false,
      descriptionIsDirty: false,
      errorNoAssertion: false,
      formsKey: {
        assertions: 1,
        endorsements: 1,
        questions: 1
      },
      fullQuestions: [],
      assertions: {
        cover: false,
        couldNotResolve: false,
        isExclusion: false,
      },
      results: {
        cover: false,
        couldNotResolve: false,
        isExclusion: false,
        isExtra: false
      },
      shouldTest: {
        cover: 'N/A',
        couldNotResolve: 'N/A',
        isExclusion: 'N/A',
      },
      selectedEndorsements: [],
      graphAvailable: true,
      isGraphLoading: false,
      isGraphVisible: true,
      graphFullscreen: false,
      searchInput: '',
      searchDimension: '',
      displayingPromptDetailsModal: false,
      initialSnapshot: null,
      lastSubmittedTestStatus: null,
      resultsSubmitted: false
    };
  },
  computed: {
    ...mapState('product', ['product']),
    ...mapGetters('product', ['isReadOnly', 'usedInput', 'usedDimensions']),
    ...mapGetters('productProperty', ['endorsements']),
    ...mapGetters('productTests', ['getTest']),
    ...mapState('productTests', ['tests', 'graph']),
    ...mapGetters('auth', ['isGuest']),
    ...mapState('route', {
      versionId: (state) => state.query.v
    }),
    isTestgen() {
      return this.testToUse && this.testToUse.definition && this.testToUse.definition.testgen && Object.keys(this.testToUse.definition.testgen).length > 0;
    },
    testToUse() {
      if (this.tests && this.tests.length && this.testId) {
        return this.tests.find((test) => test.id === this.testId);
      }
    },
    formatedQuestions() {
      const data = [];
      if (this.getInputToAsk && this.getInputToAsk.length > 0) {
        this.getInputToAsk.forEach((input) => {
          let value = this.selected[input.technicalName];
          if (!value) {
            value = input.metadata.defaultValue;
            this.selected[input.technicalName] = value;
          }
          this.inputIds = {
            ...this.inputIds,
            [input.technicalName]: true
          };
          data.push({
            id: input.technicalName,
            type: input.type.toLowerCase(),
            required: false,
            kind: 'INPUT',
            metadata: [
              {
                key: 'displayName',
                value: this.getDisplayName(input)
              },
              { key: 'defaultValue', value }
            ],
            answers: [],
            possibleAnswers: [],
            defaultValue: value,
            priority: 0
          });
        });
      }
      if (this.getDimensionToAsk && this.getDimensionToAsk.length > 0) {
        this.getDimensionToAsk.forEach((d) => {
          const prodDimension = this.product.specification.dimensions[d.type];
          const question = this.fullQuestions.find(
            (q) => q.dimensionName === d.type
          );
          if (question) {
            const answers = question.dimensionValues
              .filter((answer) => answer !== null)
              .map((answer) => ({
                id: answer.primaryKey,
                displayName: answer.displayName,
                metadata: [{ key: 'displayName', value: answer.displayName }]
              }));
            let value = this.selected[d.type];
            if (!value) {
              value = prodDimension.metadata.defaultValue;
              this.selected[d.type] = value;
            }
            this.dimensionIds = {
              ...this.dimensionIds,
              [d.type]: true
            };
            data.push({
              id: d.type,
              type: prodDimension.type.toLowerCase(),
              kind: 'DIMENSION',
              text: d.type,
              displayName: d.type,
              required: false,
              answers,
              possibleAnswers: answers.map((a) => a.primaryKey),
              defaultValue: value,
              priority: 0,
              allowMultiplePerClaim: false,
              metadata: [
                {
                  key: 'displayName',
                  value: this.getDisplayName(prodDimension)
                },
                { key: 'defaultValue', value }
              ]
            });
          }
        });
      }
      return data.sort((a, b) => {
        const noCaseA = a.metadata
          .find((md) => md.key === 'displayName')
          .value.toLowerCase();
        const noCaseB = b.metadata
          .find((md) => md.key === 'displayName')
          .value.toLowerCase();
        if (noCaseA < noCaseB) return -1;
        if (noCaseA > noCaseB) return 1;
        return 0;
      });
    },
    createQuery() {
      const query = {};
      Object.keys(this.selected).forEach((key) => {
        if (_.find(this.inputs, { technicalName: key })) {
          if (!query.input) {
            query.input = {};
          }
          if (
            this.selected[key] &&
            this.selected[key].toString().trim() !== ''
          ) {
            query.input[key] = this.selected[key];
          }
        } else if (
          typeof this.selected[key] === 'string' &&
          this.selected[key].trim().length
        ) {
          query[key] = {
            is: this.selected[key]
          };
        }
      });
      query.input = _.omitBy(query.input, _.isNil);
      query.endorsements = this.selectedEndorsements;
      return query;
    },
    /**
     * @entry Must use this.testToUse.definition.testgen.
     * @description Update the given prompt as a simple string to be stylized with html tag to see highlighted dimensions and input variables
     * @note Using replaceAll instead of the span. Maybe carefull in the future, is one occurence must not be catch (replaceAll, replace all occurences). 
     *       Using .span should be the solution.
     * @return Return a processed prompt with html tag. This string must be interpreted as html.
     */
    processPrompt() {
      const { assumptions, dimensions, input_variables, endorsements } = this.testToUse.definition.testgen;
      let { prompt } = this.testToUse.definition.testgen;
      Object.values({ assumptions, dimensions, input_variables, endorsements }).forEach((items) => {
        prompt = this.preparePrompt({ prompt, items });
      });
      return prompt;
    },
    displayedInputs() {
      Object.keys(this.inputIds).forEach((id) => {
        if (this.searchInput.length > 0) {
          // Find the corresponding input in formatedQuestions to get its display name
          const input = this.formatedQuestions.find(q => q.id === id && q.kind === 'INPUT');
          const displayNameMeta = input?.metadata?.find(meta => meta.key === 'displayName');
          const displayName = displayNameMeta?.value || '';

          // Check if either the ID or display name includes the search term
          this.inputIds[id] = id.toLowerCase().includes(this.searchInput.toLowerCase()) ||
            displayName.toLowerCase().includes(this.searchInput.toLowerCase());
        } else {
          this.inputIds[id] = true;
        }
      });
      return this.inputIds;
    },
    displayedDimensions() {
      Object.keys(this.dimensionIds).forEach((id) => {
        if (this.searchDimension.length > 0) {
          // Find the corresponding dimension in formatedQuestions to get its display name
          const dimension = this.formatedQuestions.find(q => q.id === id && q.kind === 'DIMENSION');
          const displayNameMeta = dimension?.metadata?.find(meta => meta.key === 'displayName');
          const displayName = displayNameMeta?.value || '';

          // Check if either the ID or display name includes the search term
          this.dimensionIds[id] = id.toLowerCase().includes(this.searchDimension.toLowerCase()) ||
            displayName.toLowerCase().includes(this.searchDimension.toLowerCase());
        } else {
          this.dimensionIds[id] = true;
        }
      });
      return this.dimensionIds;
    },
    showGraph() {
      return true;
    },
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    inputs() {
      return _.map(this.product.specification.in, (obj, key) => ({
        technicalName: key,
        ...obj
      }));
    },
    getInputToAsk() {
      if (!this.fullQuestions || !this.fullQuestions.length) {
        return this.usedInput;
      }
      return this.fullQuestions
        .filter((input) => input.inputName !== undefined)
        .map((input) => {
          const spec =
            this.product.specification.in &&
            this.product.specification.in[input.inputName];
          return {
            technicalName: input.inputName,
            name: input.displayName || input.name,
            __type: spec.__TYPE || 'INPUT',
            type: spec.type || 'Number',
            metadata: spec.metadata || {}
          };
        });
    },
    getDimensionToAsk() {
      if (!this.fullQuestions || !this.fullQuestions.length) {
        return this.dimensions;
      }
      const coverageDimensions = this.fullQuestions
        .filter((e) => e.dimensionName !== undefined)
        .map((dimension) => dimension.dimensionName);

      return this.dimensions.filter((dimension) =>
        coverageDimensions.includes(dimension.type)
      );
    },
    formatedAssertions() {
      const assertionsList = Object.keys(this.shouldTest).filter((assertion) => {
        return this.shouldTest[assertion];
      });
      const formatedAssertions = assertionsList.map((id) => {
        let value = 'undefined';
        if (typeof this.assertions[id] !== 'undefined') {
          value = String(this.assertions[id]);
        }

        this.setStyleActive(id, value, value);
        const snakeAssertion = id.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`
        );
        return {
          id,
          type: 'boolean',
          required: false,
          metadata: [
            {
              key: 'displayName',
              value: this.$t(`tests.${snakeAssertion}`)
            }
          ],
          answers: [],
          possibleAnswers: [],
          value,
          defaultValue: value,
          priority: 0
        };
      });
      return formatedAssertions;
    },
    formatedEndorsements() {
      if (!this.endorsements.length || this.endorsements.length === 0) return [];

      const answers = this.endorsements.map((en) => {
        return {
          id: en.name,
        };
      });
      return [
        {
          id: 'endorsements',
          type: 'enum',
          required: false,
          answers,
          possibleAnswers: this.endorsements.map((en) => en.name),
          defaultValue: this.selectedEndorsements,
          allowMultiplePerClaim: true,
          priority: 0
        }
      ];
    },
    formatedResultsData() {
      const data = [];
      Object.entries(this.shouldTest).forEach((test) => {
        if (test[1] !== undefined && this.assertions[test[0]] !== undefined) {
          const label = test[0].replace(
            /[A-Z]/g,
            (letter) => `_${letter.toLowerCase()}`
          );
          data.push({
            status: this.assertions[test[0]] === this.results[test[0]],
            description: this.$t(`tests.${label}`),
            expected: `${this.assertions[test[0]]}`,
            actual: `${this.results[test[0]]}`
          });
        }
      });
      return data;
    },
    viewAsText() {
      return this.viewAsJSON
        ? this.$t('tests.view_table')
        : this.$t('tests.view_json');
    },
    isTestSucceed() {
      let fails = 0;
      // On product creation, check if the test name is set or not
      if (!this.testName || this.testName.length === 0) {
        fails += 1;
      }
      // Check the values of the assertions
      Object.entries(this.shouldTest).forEach((test) => {
        if (
          test[1] !== undefined &&
          this.assertions[test[0]] !== undefined &&
          this.assertions[test[0]] !== this.results[test[0]]
        ) {
          fails += 1;
        }
      });
      // Check if all assertions are undefined
      const totalAssertions = Object.keys(this.assertions).length;
      let countAssertionValidation = 0;
      Object.keys(this.assertions).forEach((assertionId) => {
        if (this.assertions[assertionId] === undefined) {
          countAssertionValidation += 1;
        }
      })
      if (countAssertionValidation === totalAssertions) {
        fails += 1;
      }
      return fails === 0;
    },
    isTestFailed() {
      return !this.isTestSucceed;
    },
    testDefinition() {
      const testDefinition = {
        input: this.selected,
        output: {},
        endorsements: this.selectedEndorsements
      };

      Object.keys(this.assertions).forEach((key) => {
        if (this.shouldTest[key]) {
          testDefinition.output[key] = this.assertions[key];
        }
      });
      return testDefinition;
    },
    currentSnapshot() {
      return {
        testDefinition: _.cloneDeep(this.testDefinition),
        testName: this.testName,
        assertions: _.cloneDeep(this.assertions),
        formsKey: _.cloneDeep(this.formsKey),
        testDescription: this.testDescription,
      };
    },
    testIsNew() {
      return !this.testId;
    },
  },
  watch: {
    testToUse: {
      async handler() {
        this.setSelectedEndorsements();
        await this.getQuestionsByCoverage();

        // Don't call checkCoverage on initial load, even for existing tests
        // Leave results hidden until manual submission

        this.checkValidityName();
        this.reRenderForms();

        // Set initial snapshot with current status
        this.captureSnapshot();

        // Only set the status flag but don't show results yet
        if (this.testToUse && this.testToUse.id && !this.isNewTest) {
          this.lastSubmittedTestStatus = this.isTestSucceed;
        }

        this.isLoading = false;
      },
    },
    testId: {
      handler: async function (val) {
        if (val) {
          await this.fetchTests();
        }
      }
    },
    tests() {
      if (this.tests && this.testId && this.tests.length > 0) {
        const test = this.tests.find((t) => t.id === this.testId);
        if (test) {
          this.populateForm(test);
        } else {
          this.$router.push({
            name: 'product-tests',
            params: this.$router.currentRoute.params,
            query: this.$router.currentRoute.query
          });
        }
      }
    }
  },
  mounted: async function () {
    window.addEventListener('beforeunload', this.onClose);
    try {
      // Try to fetch the tests if they're not in the store.
      if (!this.tests || this.tests.length === 0) {
        await this.fetchTests();
      }
      await this.fetchProductProperties(this.product.id);
      if (this.$route.params.testId) {
        if (this.$route.params.testId === 'new') {
          this.isNewTest = true;
          const { copy } = this.$route.query;
          if (copy) {
            this.isCopyTest = true;
            this.testName = `${this.testName} - (Copy)`;
            this.testId = Number.parseInt(copy, 10);
          } else {
            this.testToUse = {};
            this.resetForm();
          }
        } else {
          this.testId = Number.parseInt(this.$route.params.testId, 10);
        }
        this.checkQueryAnswers();
      }
      // Set initial snapshot with current status
      this.captureSnapshot();
    } catch (error) {
      console.error('Error in mounted hook:', error);
    }
  },
  methods: {
    ...mapActions({ fetchTests: 'productTests/fetch' }),
    ...mapActions('productProperty', ['fetchProductProperties']),
    preparePrompt(options) {
      let { prompt, items } = options;
      items && Object.values(items).forEach((item) => {
        if (item.relevant_word.length > 0) {
          prompt = prompt.replaceAll(item.relevant_word, `<div class="highlight" rel="${item.id}">${item.relevant_word}</div>`);
        }
      });
      return prompt;
    },
    setStyleActive(id, valueToCheck, defaultValue = 'N/A') {
      if (!this.selected[id]) {
        this.selected[id] = defaultValue;
      }
      [...document.getElementsByClassName(`assertion-${id}`)].forEach(item => item.classList.remove('active'));
      document.getElementById(`assertion-${id}-${valueToCheck.toString()}`)?.classList.add('active');
    },
    captureSnapshot() {
      this.initialSnapshot = _.cloneDeep(this.currentSnapshot);
    },
    hasUnchangedUnsaved() {
      return !_.isEqual(this.initialSnapshot, this.currentSnapshot);
    },
    async handleUnsavedChanges(next) {
      const hasUnsavedChanges = this.hasUnchangedUnsaved();

      try {
        if (hasUnsavedChanges) {
          await MessageBox.confirm(
            this.$t(`tests.v2.unsaved_changes`),
            this.$t(`tests.v2.unsaved_changes_title`)
          );
          if (next) {
            next();
          }
        } else {
          next();
        }
      } catch (error) {
        console.log(error);
      }

    },
    onClose: function (event) {
      if (this.hasUnchangedUnsaved()) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    async duplicateTest() {
      await this.$router.push({
        path: './new',
        query: { ...this.$route.query, copy: this.testId }
      });
      window.location.reload();
    },
    async deleteTest() {
      try {
        await MessageBox.confirm(
          this.$t('tests.delete_test_confirmation-message'),
          this.$t('tests.delete_test_confirmation-title')
        );
      } catch (err) {
        return;
      }
      await api.deleteTest(this.testId);
      const { version } = this.$route.query;
      this.$router.push(version ? { path: '.', query: { version } } : '.');
    },
    graphFullscreenToggle() {
      this.graphFullscreen = !this.graphFullscreen;
    },
    async getQuestionsByCoverage() {
      try {
        const query = { endorsements: [...this.selectedEndorsements] };
        const { data } = await api.executeAPIQuery(
          'cover',
          this.product.id,
          query,
          this.versionId
        );
        // only update dimension on first call
        if (!this.dimensions) {
          // @TODO Fix this with a new API that returns all values
          // with no need of all this mapping.
          const productDimensions = this.usedDimensions;
          const { terms } = this.product;

          this.dimensions = Object.keys(productDimensions).map((i) => {
            const dim = productDimensions[i];

            const term = terms[i]
              ? terms[i]
                .filter((t) => Object.keys(dim).includes(t.name))
                .reduce(
                  (a, t) => [
                    ...a,
                    ...t.definitions.map((d) => {
                      return d.primaryKey;
                    })
                  ],
                  []
                )
              : [];

            const filteredQuestions = data.question
              ? data.questions.filter((q) => q.type === i)
              : [];
            const question =
              data.questions && filteredQuestions.length
                ? filteredQuestions[0]
                : {
                  answers: []
                };

            const { dimensions } = this.product.specification;
            const defaultValue = extractDefaultValues(dimensions).filter(
              (dv) => dv.type === i
            );

            return {
              type: i,
              answers: [
                ...new Set([
                  ...Object.keys(dim).map((d) => dim[d].value),
                  ...term,
                  ...question.answers,
                  ...defaultValue.map((dv) => dv.value)
                ])
              ].sort()
            };
          });
        }
        this.fullQuestions = data.questions || [];
      } catch (error) {
        console.error('Error fetching questions by coverage:', error);
        // Set empty questions to prevent further errors
        this.fullQuestions = [];
        if (!this.dimensions) {
          // Initialize dimensions with basic data from product if API fails
          const productDimensions = this.usedDimensions;
          this.dimensions = Object.keys(productDimensions).map((i) => {
            const dim = productDimensions[i];
            return {
              type: i,
              answers: [...new Set(Object.keys(dim).map((d) => dim[d].value))].sort()
            };
          });
        }
      }
    },
    async checkCoverage() {
      try {
        const query = this.createQuery;
        const { data } = await api.executeAPIQuery(
          'cover',
          this.product.id,
          query,
          this.versionId
        );
        this.response = JSON.stringify(data, null, 2);

        // Don't call renderRule here at all, it will be called explicitly in createUpdateTest

        this.results = {
          cover: data.cover,
          isExclusion: data.isExclusion,
          couldNotResolve: data.couldNotResolve,
          isExtra: data.isExtra || false
        };
        this.isLoading = false;
        if (this.isNewTest && !this.testName) {
          this.$nextTick(() => {
            this.$refs.nameInput && this.$refs.nameInput.focus();
          });
        }
        // Update the lastSubmittedTestStatus property after submission
        this.lastSubmittedTestStatus = this.isTestSucceed;
      } catch (error) {
        console.error('Error checking coverage:', error);
        this.response = JSON.stringify({ error: 'Failed to check coverage' }, null, 2);

        // Set default results in case of error
        this.results = {
          cover: false,
          isExclusion: false,
          couldNotResolve: false,
          isExtra: false
        };

        this.isLoading = false;
        // Update status anyway to prevent interface from breaking
        this.lastSubmittedTestStatus = false;

        // Show an error message to the user
        Message.error('Failed to check coverage. Please try again.');
      }
    },
    populateForm(item) {
      if (item.name && item.definition) {
        const { copy } = this.$route.query;
        this.testName = item.name ? `${item.name}${copy ? ' - (Copy)' : ''}` : undefined;
        this.testDescription = item?.description;
        this.selected = { ...item.definition.input };
        this.selectedEndorsements = item.definition.endorsements || [];
        Object.keys(this.shouldTest).forEach((key) => {
          if (this.shouldTest[key]) {
            this.assertions[key] = item.definition.output[key];
          }
        });
      }
    },
    resetForm() {
      this.$emit('update');
      this.selected = {};
      this.selectedEndorsements = [];
      this.testName = undefined;
      this.testDescription = undefined;
      this.testId = null;
      this.lastSubmittedTestStatus = null;
      this.resultsSubmitted = false;
      // Also reset the results
      this.results = {
        cover: false,
        couldNotResolve: false,
        isExclusion: false,
        isExtra: false
      };
      this.nameIsDirty = false;
      this.descriptionIsDirty = false;
      this.resetAssertions();
    },
    async createUpdateTest() {
      const testName = this.testName?.trim() || '';
      this.errorNoName = false;
      if (!testName) {
        this.errorNoName = true;
        this.nameIsDirty = true;
        return false;
      }
      this.errorNoAssertion = false;
      if (!Object.values(this.testDefinition.output).some((e) => e !== undefined)) {
        this.errorNoAssertion = true;
        return false;
      }

      // update initial snapshot with current status
      this.captureSnapshot();

      try {
        let test;
        if (!this.isNewTest) {
          // get the testgen metadata from the state test object
          const initialTest = this.tests.find((t) => t.id === this.testId);
          if (initialTest && initialTest.definition.testgen) {
            this.testDefinition.testgen = initialTest.definition.testgen;
          }
          test = await api.updateTest(
            this.testId,
            testName,
            this.testDefinition,
            this.product.id,
            this.testDescription,
          );
          if (test.status ? [200, 201].includes(test.status) : false) {
            Message.success(this.$t('tests.update_test_success'));
          }
        } else {
          test = await api.createTest(this.product.id, testName, this.testDefinition, this.testDescription,);
          if (test.status ? [200, 201].includes(test.status) : false) {
            Message.success(this.$t('tests.create_test_success'));
            this.testId = test.data.id;
            this.isNewTest = false;
            delete this.$route.query.copy;
            this.$router.replace({
              name: 'product-tests-editor',
              params: { testId: this.testId },
              query: this.$route.query
            });
          }
        }

        await this.fetchTests();

        // Always set resultsSubmitted to true even if subsequent API calls fail
        this.resultsSubmitted = true;

        try {
          await this.checkCoverage();
          // Explicitly call renderRule with force=true to ensure the graph is displayed
          await this.renderRule(true);
        } catch (apiError) {
          console.error('API error during test submission:', apiError);
          // We still mark the test as submitted even if the API calls fail
        }

        return test.data.id;
      } catch (error) {
        console.error('Error creating/updating test:', error);
        Message.error('Failed to submit test. Please try again.');
        return false;
      }
    },
    handleGeneralAction(command) {
      this[command]();
    },
    async fillWithDefaults() {
      const inputs = this.getInputToAsk;
      if (Object.keys(this.selected).length === 0) this.selected = {};
      inputs.forEach((input) => {
        if (input.metadata.defaultValue) {
          this.selected[input.technicalName] = input.metadata.defaultValue;
        }
      });
      this.dimensions.forEach((d) => {
        const prodDimension = this.product.specification.dimensions[d.type];
        if (prodDimension.metadata.defaultValue) {
          this.selected[d.type] = prodDimension.metadata.defaultValue;
        }
      });
      this.setFormatedQuestions();
      this.reRenderForms(['assertions', 'endorsements']);

      // Don't automatically check coverage
      // User needs to press submit first
    },
    async resetValues() {
      const inputs = this.getInputToAsk;
      inputs.forEach((input) => {
        this.selected[input.technicalName] = ' ';
      });
      this.dimensions.forEach((d) => {
        this.selected[d.type] = ' ';
      });
      this.selectedEndorsements = [];
      this.resetAssertions();
      await this.getQuestionsByCoverage();
      this.reRenderForms();

      // Don't automatically check coverage
      // User needs to press submit first

      // Also reset the results submitted flag
      this.resultsSubmitted = false;
    },
    resetAssertions() {
      this.assertions = { ...this.assertions };
      Object.keys(this.shouldTest)
        .filter((assertion) => {
          return this.shouldTest[assertion];
        })
        .forEach((assertion) => {
          this.assertions[assertion] = undefined;
        });
    },
    toggleJSONView() {
      this.viewAsJSON = !this.viewAsJSON;
    },
    toggleEditingName() {
      this.editingName = !this.editingName;
      if (this.editingName) {
        // Reset dirty flag when starting to edit
        this.nameIsDirty = false;
        this.$nextTick(() => {
          this.$refs.nameInput && this.$refs.nameInput.focus();
        });
      } else {
        // When exiting edit mode, mark as dirty and validate
        this.nameIsDirty = true;
        this.checkValidityName();
      }
    },
    toggleEditingDescription() {
      this.editingDescription = !this.editingDescription;
      if (this.editingDescription) {
        // Reset dirty flag when starting to edit
        this.descriptionIsDirty = false;
        this.$nextTick(() => {
          this.$refs.descriptionInput && this.$refs.descriptionInput.focus();
        });
      } else {
        // When exiting edit mode, mark as dirty
        this.descriptionIsDirty = true;
      }
    },
    checkValidityName() {
      this.nameIsDirty = true;
      this.errorNoName = false;
      if (!this.testName || this.testName.length === 0) {
        this.errorNoName = true;
      }
    },
    checkValidityDescription() {
      this.descriptionIsDirty = true;
    },
    getDisplayName(variable) {
      return displayName(variable);
    },
    setQuestionValue(id, value) {
      this.selected[id] = value;
      this.setStyleActive(id, value);
    },
    answerQuestion(data) {
      this.selected[data.question.id] = data.answer;
    },
    clearQuestion(data) {
      this.selected[data.question.id] = undefined;
    },
    setAssertionExpectedVal(data) {
      this.assertions[data.question.id] = data.answer === 'true';
    },
    unsetAssertion(data) {
      this.assertions[data.question.id] = undefined;
    },
    setSelectedEndorsements() {
      this.selectedEndorsements = this.testToUse?.definition?.endorsements || this.$refs?.endorsements?.formData?.lines?.root?.endorsements || [];
    },
    reRenderForms(ignoreForms = []) {
      Object.entries(this.formsKey).forEach((e) => {
        this.formsKey[e[0]] = ignoreForms.includes(e[0]) ? e[1] : e[1] + 1;
      });
    },
    async showRule() {
      if (this.isGraphLoading) return;
      this.isGraphLoading = true;

      await this.renderRule(true);

      this.isGraphLoading = false;
    },
    async hideRule() {
      this.isGraphLoading = false;
    },
    // helper function to find parent rule id for a found term.id
    findParentRuleId(arr, targetId) {
      for (const rule of arr) {
        if (rule.graph && rule.graph.terms) {
          const term = this.findTermInArray(rule.graph.terms, targetId);
          if (term) {
            return rule.id;
          }
          const parentRuleId = this.findParentRuleId(rule.graph.terms, targetId);
          if (parentRuleId) {
            return parentRuleId;
          }
        }
      }
      return null;
    },
    // helper function to find term.id in nested rules array
    findTermInArray(arr, targetId) {
      for (const term of arr) {
        if (term.id === targetId) {
          return term;
        }
        if (term.terms) {
          const foundTerm = this.findTermInArray(term.terms, targetId);
          if (foundTerm) {
            return foundTerm;
          }
        }
      }
      return null;
    },
    async renderRule(force = false) {
      if ((force === false && !this.showGraph) || !this.resultsSubmitted) return;

      try {
        const { data } = await api.executeAPIQuery(
          'match',
          this.product.id,
          this.createQuery
        );

        if (this.editor) {
          this.editor.destroy();
          this.editor = null;
        }

        if (data && data.graph) {
          this.editor = new RuleEditor({
            container: this.$refs.editor,
            data: data.graph,
            product: this.product,
            readOnly: true,
            lang: window.localStorage.locale || 'en',
            translate: this.$t.bind(this)
          });
          // redirect to rule editor when a rule is clicked
          this.editor.on('onSelect', (selectionValue) => {
            const termId = selectionValue?.id;
            if (termId) {
              const parentRuleId = this.findParentRuleId(this.product.rules, termId);
              const route = {
                name: `product-rules-edit`,
                params: { ruleId: parentRuleId || this.product?.mainRuleId },
                hash: `#${selectionValue?.id}`,
              }
              const { version } = this.$route.query;
              if (version) {
                route.query = { version };
              }
              this.$router.push(route);
            }
          });
          this.editor.render();
        } else {
          // Handle case when graph data is missing but API call succeeded
          console.warn('No graph data available for rendering');
          this.$refs.editor.innerHTML = '<div class="no-graph-message">No graph data available</div>';
        }
      } catch (error) {
        console.error('Error rendering rule:', error);
        // Display a user-friendly error message in the graph container
        if (this.$refs.editor) {
          this.$refs.editor.innerHTML = '<div class="graph-error-message">Failed to load graph data</div>';
        }
      } finally {
        // Make sure loading indicator is hidden
        this.isGraphLoading = false;
      }
    },
    getLabel(dim, label) {
      if (label === '_OTHER') return this.$t('rule-editor.other');
      const result = (this.product.terms[dim] || []).reduce((val, t) => {
        const def = (t.definitions || []).find((d) => d.primaryKey === label);
        return def ? def.displayName || def.primaryKey : val;
      }, '');
      return result || label;
    },
    checkQueryAnswers() {
      const { query: apiQuery } = this.$route;
      const { endorsements } = apiQuery;
      delete apiQuery.endorsements;
      (Object.keys(apiQuery) || []).forEach((k) => {
        this.selected[k] = apiQuery[k];
      });
      this.selectedEndorsements = endorsements || [];
    },
    endorsementsChange() {
      this.$nextTick(async () => {
        const { endorsements } = this.$refs.endorsements.formData.lines.root;
        if (JSON.stringify(endorsements) !== JSON.stringify(this.selectedEndorsements)) {
          this.selectedEndorsements = endorsements || [];
          await this.getQuestionsByCoverage();
          this.reRenderForms(['assertions', 'endorsements']);
          // this.checkCoverage();
        }
      });
    },
    displayPromptDetailsModal() {
      this.displayingPromptDetailsModal = true;
    },
    hidePromptDetailsModal() {
      this.displayingPromptDetailsModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@axatechlab/assets/scss/variables";
$redStatus: #e7473a;
$greenStatus: #17da99;
$lightGrey: #fefefe;
$lightPurple: #cacaf6;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadingOverlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  position: absolute;
  z-index: 5;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, .25);
  visibility: visible;
  transition: all 1s;
  transform-origin: top center;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    backdrop-filter: blur(50px);
  }

  &-arrows {
    transform-origin: center;
    position: absolute;
    top: 25%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -200px;
    color: $color-axa-blue;
    animation: spin 1s infinite linear;
  }
}

.exclamation-circle-as-a-i {
  cursor: pointer;
  transform: rotate(180deg);
  float: right;
  margin-top: 35px;
  margin-right: 10px;
  transition: scale .6s ease;

  &:hover {
    transform: rotate(180deg) scale(1.1);
  }
}

#itemContainer #itemBar #editBar {
  position: sticky !important;
}

.test {
  position: relative;
  width: 100%;
  counter-reset: number;

  .toolbar {
    position: sticky;
    width: inherit;
    top: 0;
    margin-top: 0 !important;
    z-index: 1;
    padding-bottom: 20px;

    &-buttons {
      display: block;
    }

    &-back {
      position: relative;
      left: 0;
    }

    background-color: rgb(255, 255, 255, .95);
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.2);
  }

  h3.sectionTitle {
    font-size: 1.1rem;
    font-weight: bold;

    &:before {
      content: counter(number) ". ";
      counter-increment: number;
    }
  }

  &-wrapper {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .statusAndName {
    font-weight: 400;
    font-size: 1.4em;
    line-height: 1.4em;

    &-name,
    &-description {
      cursor: text;
      margin-left: 20px;

      & .statusAndName-pencil {
        opacity: 0;
        transition: opacity .3s linear;
      }

      &:hover .statusAndName-pencil,
      &:focus .statusAndName-pencil,
      &:active .statusAndName-pencil {
        opacity: 1;
      }
    }

    &-description {
      font-size: 1.0rem;
      color: $color-axa-blue;
    }

    .status {
      vertical-align: middle;

      &:before {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        vertical-align: middle;
        float: left;
        margin: 0 5px 3px 0;
        margin-top: 7px;
        content: '';
      }

      &.success::before {
        background-color: $greenStatus;
      }

      &.failed::before {
        background-color: $redStatus;
      }
    }

    .icon {
      width: 0.9em;
      height: 0.9em;
      color: darken($lightPurple, 10%);
      vertical-align: unset;
      margin-left: 1em;
      cursor: pointer;
    }

    .testDescription {
      font-size: 1.0rem;
      color: $color-axa-blue;
    }

    input {
      background-color: transparent;
      border: none;
      width: 90%;
      padding: 0;

      &:focus {
        outline: none;
      }
    }
  }


  &-header {
    position: relative;
    width: 100%;

    .actionsDropdown {
      .el-button {
        border-radius: 5px;
        background-color: $lightGrey;
        color: #464dc5;
        border-color: $lightPurple;
        font-weight: 400;
        padding: 10px 15px;
        text-transform: none;
      }
    }

    ::v-deep .testActionButton {
      .el-button {
        font-weight: 400;
        padding: 11px 15px;
        text-transform: none;
        border: 1px solid $color-axa-blue;

        i {
          border-color: $color-axa-blue;
        }
      }

      &.createButton {
        .el-button {
          background-color: $color-axa-blue;
          color: $color-axa-blue;
        }
      }

      &.updateButton {
        .el-button {
          background-color: $lightPurple;
          color: #464dc5;
        }
      }
    }


  }


  &-container {
    overflow-y: scroll !important;
    overflow-x: hidden !important;

    h2 {
      font-size: 1.6em;
      font-weight: 600;
      margin: 0.5em 0;
      padding: 0px 20px;
    }

    transform: translate3d(0, 0, 0);

    &.isReadOnly {
      grid-template-columns: 100%;
    }

    #settings {
      background-color: #fff;
      box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.2);
      z-index: 2;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      flex-basis: 40%;
      max-width: 600px;

      .back-button {
        top: auto;
        left: auto;
      }
    }

    #assertionsForm {
      ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 15px 35px;

        .el-form-item__error {
          display: none;
        }
      }
    }

    #endorsementsForm {
      ::v-deep .el-form {
        grid-template-columns: 1fr;
        grid-gap: 15px;

        .el-form-item__label {
          display: none;
        }
      }
    }
  }

  #results {
    background-color: #fff;
    overflow: hidden !important;

    .graph-fullscreen-toggle {
      margin-right: 10px;
      margin-top: 10px;
      position: fixed;
      z-index: 10;
      right: 0;
      background-color: white;
      color: #212529;
      box-shadow: -1px 7px 8px rgba(0, 0, 0, 0.4);
      border: 0;
      text-transform: uppercase;
    }

    .graph-fullwidth {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      border: solid 1px #e5e5e5;
    }

    .graph {
      position: relative;
      background-color: #f5f5f8;
      width: 100%;
      height: 100%;

      ::v-deep .editBar.right.retract {
        position: absolute;
      }
    }

    .graph-toggle {
      border-bottom: 1px solid $lightPurple;
      font-weight: 600;
      background-color: #f5f5f8;
      display: flex;
      height: 30px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .graph-toggle-label {
      margin-left: 0.5em;
    }

    .graph-error {
      background-color: $color-silver-light;
      box-shadow: inset 0 0 0 -10px #888;
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 10rem;
      transition: all .3s;

      div {
        margin: 0 auto;
        font-size: 1.3em;

        color: darken($lightPurple, 50%);
        text-shadow: #e0e0e0 1px 1px 0;
      }
    }

    #outputs {
      position: sticky;

      .status {
        padding: 5px 25px;
        color: #fff;
        font-weight: 400;

        &:before {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          vertical-align: middle;
          display: inline-block;
          margin: 0 5px 3px 0;
          background-color: #fff;
          content: ' ';
        }

        &.failed {
          background-color: $redStatus;
        }

        &.success {
          background-color: $greenStatus;
        }
      }

      .assertions {
        border-bottom: 1px solid $lightPurple;
        // border-top: 1px solid $lightPurple;
      }

      #assertionsForm,
      #endorsementsForm,
      #questionsForm {
        padding: 0 25px 40px;

        ::v-deep .el-form {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 15px 40px;

          >div {
            min-width: 0;
          }

          .el-form-item {
            margin-bottom: 0;

            .el-form-item__label {
              float: none;
              margin-bottom: 0;
              line-height: 25px;
              width: 100%;

              //This should be edited when the required property is fixed on ClaimHandler
              &::before {
                display: none;
              }

              div {
                text-align: left;
                white-space: nowrap;
                max-width: 95%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            .boolean-question {
              label {
                margin-top: 0 !important;

                &:first-of-type {
                  margin-left: 0 !important;
                }

                >span {
                  padding: 11px 0;
                }
              }
            }

            .date-picker-widget {
              width: 100% !important;
            }

            .claim-handler-select {
              width: 100%;

              .el-select {
                display: block;
              }
            }
          }
        }
      }

      #assertionsForm {
        ::v-deep .el-form {
          display: flex;
          flex-wrap: wrap;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 15px 35px;

          .el-form-item__error {
            display: none;
          }
        }
      }

      #endorsementsForm {
        ::v-deep .el-form {
          grid-template-columns: 1fr;
          grid-gap: 15px;

          .el-form-item__label {
            display: none;
          }
        }
      }
    }

    #results {
      background-color: #fff;

      .graph-fullscreen-toggle {
        margin-right: 10px;
        margin-top: 10px;
        position: fixed;
        z-index: 10;
        right: 0;
        background-color: white;
        color: #212529;
        box-shadow: -1px 7px 8px rgba(0, 0, 0, 0.4);
        border: 0;
        text-transform: uppercase;
      }

      .graph-fullwidth {
        position: absolute;
        width: 100%;
        height: 95%;
        top: 0;
        left: 0;
        z-index: 100;
        border: solid 1px #e5e5e5;
      }

      .graph {
        position: relative;
        background-color: #f5f5f8;
        height: auto;

        ::v-deep .editBar.right.retract {
          position: absolute;
        }
      }

      .graph-visible {
        height: 100vh;
      }

      .graph-toggle {
        border-bottom: 1px solid $lightPurple;
        font-weight: 600;
        background-color: #f5f5f8;
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .graph-toggle-label {
        margin-left: 0.5em;
      }

      .graph-error {
        display: grid;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10rem;

        div {
          margin: 0 auto;
          font-size: 1.3em;

          color: darken($lightPurple, 50%);
          text-shadow: #e0e0e0 1px 1px 0;
        }
      }
    }
  }
}

.dropdown {
  margin: 0 8px;
}

.error-no-data {
  font-size: 1rem;
  color: $color-red;
  padding-left: 1.5rem;
}

.buttonsList {
  display: inline-block;
}

.formElement {
  &--radio {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 60% 40%;

    & .buttonsList {
      margin-bottom: 5px;

      & button {
        border: 1px solid $color-border;
        border-radius: 5px;

        &.active {
          color: white;
          background-color: $color-axa-blue;
        }
      }
    }
  }
}

.textarea {
  padding: 10px 20px;
  border: 1px solid #00008f;
  border-radius: 6px;
  margin-bottom: 30px;
}

.highlight {
  display: inline-block;
  border-radius: 5px;
  background-color: $color-highlight;
  padding: 0 4px;
  height: 24px;
  color: $color-axa-blue;
}

::v-deep .UiThreeDotsDropDown {
  & .el-button {
    padding: 0;

    & i {
      font-size: 12px;
      border: 1px solid $color-axa-blue;
      background-color: transparent;
      transition: all .3s;
      padding: .62rem .5rem;
      line-height: 1rem;
      font-size: 1rem;
      color: $color-axa-blue;
      margin-left: .5rem;

      &:hover,
      &::selection {
        background-color: $color-axa-blue;
        color: white;
      }
    }
  }
}

.please-submit-message {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  div {
    p {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 500;
      color: $color-axa-blue;
    }
  }
}

.graph-error-message,
.no-graph-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #666;
  background-color: #f5f5f8;
}

.graph-error-message {
  color: $redStatus;
}

#outputs {
  position: sticky;

  .status {
    padding: 5px 25px;
    color: #fff;
    font-weight: 400;

    &:before {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      vertical-align: middle;
      display: inline-block;
      margin: 0 5px 3px 0;
      background-color: #fff;
      content: ' ';
    }

    &.failed {
      background-color: $redStatus;
    }

    &.success {
      background-color: $greenStatus;
    }
  }

  .assertions {
    border-bottom: 1px solid $lightPurple;
    // border-top: 1px solid $lightPurple;
  }

  #assertionsForm,
  #endorsementsForm,
  #questionsForm {
    padding: 0 25px 40px;

    ::v-deep .el-form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 15px 40px;

      >div {
        min-width: 0;
      }

      .el-form-item {
        margin-bottom: 0;

        .el-form-item__label {
          float: none;
          margin-bottom: 0;
          line-height: 25px;
          width: 100%;

          //This should be edited when the required property is fixed on ClaimHandler
          &::before {
            display: none;
          }

          div {
            text-align: left;
            white-space: nowrap;
            max-width: 95%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .boolean-question {
          label {
            margin-top: 0 !important;

            &:first-of-type {
              margin-left: 0 !important;
            }

            >span {
              padding: 11px 0;
            }
          }
        }

        .date-picker-widget {
          width: 100% !important;
        }

        .claim-handler-select {
          width: 100%;

          .el-select {
            display: block;
          }
        }
      }
    }
  }

  #assertionsForm {
    ::v-deep .el-form {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 15px 35px;

      .el-form-item__error {
        display: none;
      }
    }
  }

  #endorsementsForm {
    ::v-deep .el-form {
      grid-template-columns: 1fr;
      grid-gap: 15px;

      .el-form-item__label {
        display: none;
      }
    }
  }
}

#assertionsForm {
  ::v-deep .el-form {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px 35px;

    .el-form-item__error {
      display: none;
    }
  }
}

#endorsementsForm {
  ::v-deep .el-form {
    grid-template-columns: 1fr;
    grid-gap: 15px;

    .el-form-item__label {
      display: none;
    }
  }
}

.statusAndName {

  &-name,
  &-description {
    ::v-deep .b-form-input {
      background-color: transparent;
      border: none;
      width: 90%;
      padding: 0;
      box-shadow: none;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.placeholder-text {
  color: #6c757d;
  /* Bootstrap's default placeholder color */
  font-style: italic;
}
</style>